import { IMatterListEntry } from '@app/features/+matter-list/models';
import { IPerson } from '@app/shared/models';
import { ICardListEntry } from '@app/features/+card/models';

export interface MatterDetailsMetaInfo {
  matterId: string;
  matterEntry?: IMatterListEntry;
  cardEntries?: ICardListEntry[];
}

export interface IMatterDetailRelationship {
  parentTableId: string;
  childTableId: string;
  order: number;
  singleton: boolean;
}

export interface IMatterDetailEntry {
  __id?: string;
  __className?: string;
  __fileOrder: number;
  __displayOrder: number;
  __name: string;
  __description: string;
  __tableId?: string;
  __relatedTables?: string[];
  tableId: string;
  subsTableId?: string;
  detailType: EMatterDetailType;
  singleton?: boolean;
  error?: string;
  hiddenOnMatter?: boolean;
  matterDescriptions?: IMatterDescription;
  isCard: boolean;
  default?: boolean;
  context?: IMatterDetailEntryContext; // move logic from front-end to back-end and put under optional context
  children?: IMatterDetailEntry[];
  __relatedParentTableId?: string;
  __matterCardId?: string;
  recordId?: string;
  flatListIndex?: number; // index that show its position in the detailEntries flat list (Not being constructed to tree list)
  matterType?: boolean; // Location of the matter type detail item. Only one item in the list should have mater type === true
  isTransient?: boolean; // Indicates this record does not exist in the database and is used for display only
  handled?: boolean; // Taken from sorting algorithm desktop uses
  __classes?: string[];
  cardId?: string;
}

export interface IDefinableTable extends IMatterDetailEntry {
  __matterId: string;
  layoutId: string;
  desc: string;
  needsContentApp?: boolean;
  basedOnCard?: boolean;
  cardId?: string;
  // fields: Array<ITableField>; // for update payload
  // __fields: Array<{
  //     fieldId: string;
  //     fieldName: string;
  // }>;
  // convenient: hold list of fieldId-fieldValue mappings so that we can construct relatedLayout object after user updating layout form
  descriptionFn?: unknown;
  descriptionProp?: string;
}

export interface IMatterCard extends IMatterDetailEntry {
  cardId: string;
  cardPersonList: string[];
  __inherited: string;
  reference: string;
  relatedTable: IDefinableTable; // for updating related definableTable of this card role
  persons: IPerson[]; // convenient: list of persons being used by this card role
  unusedPersons: IPerson[]; // convenient: list of persons in this card not being used by this card role
  isDebtor: boolean;
  isPersonCard: boolean;
  cardDefinableTable?: IDefinableTable;
  cardShortName?: string;
  __classes?: string[];
  phoneNumberList: Array<MatterCardPhoneContact>; // convenient: displaying all phoneNumbers in callback request
  personSchemas?: IPersonSchema[]; // convenient: simple person info
  cardInfo?: { email: string; type: string }; // card-info inside matter-card (e.g. type)
}

export interface IPersonSchema {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
}

export interface MatterCardPhoneContact {
  name?: string;
  numberType: string;
  number: string; // eslint-disable-line
}

export interface ICardType extends IMatterDetailEntry {
  cardPersonList: string[];
  context: IMatterDetailEntryContext;
  detailType: number;
  isCard: boolean;
  isDebtor: boolean;
  persons: IPerson[];
  singleton: boolean;
  subsTableId: null;
  tableId: string;
  unusedPersons: IPerson[];
  __className: string;
  __classes: string[];
  __displayOrder: number;
  __fileOrder: number;
  __name: string;
  __tableId: string;
}

export interface IMatterDetailEntryContext {
  detailNumber?: string;
  displayDebtorForAccounting?: boolean;
  displayPersons?: boolean;
  hidden?: boolean;
  requireContentApp?: boolean;
  showContextMenu?: boolean;
  singleton?: boolean;
  type?: MatterDetailContextType;
}

export interface IMatterDescription {
  firstDescription: string;
  secondDescription: string;
}

export enum EMatterDetailType {
  Card = 1,
  DefinableTable = 2,
}

export enum MatterDetailsQueryType {
  All = 0,
  MatterCards = 1,
  Definables = 2,
  EmptyCards = 3,
  EmptyDefinables = 4,
}

export interface INewDetailParams {
  detailEntry: IMatterDetailEntry;
  isPersonCard?: boolean;
  isCard?: boolean;
}

export interface IMatterCardUpdateSuccess {
  matterCard: IMatterCard;
  oldId?: (detailEntries: IMatterDetailEntry[]) => string;
  preUpdate?: (detailEntries: IMatterDetailEntry[]) => IMatterDetailEntry[];
  postUpdate?: () => void;
}

export interface IPersonActingActionParams {
  matterId: string;
  matterCard: IMatterCard;
  person: IPerson;
}

export interface IMatterTablesState {
  matterCards: IMatterCard[];
  definableTables: IDefinableTable[];
}

export interface IMatterDetailsInfo {
  matterFileNumber?: string;
  matterDescription: string;
  corrupted?: boolean;
  customDescription?: string; // description field under matterCore or matterListEntry's customDescription
  matterType?: string;

  // fields from IMatterDetailEntry
  matterTypeId?: string;
  matterState?: string;
  matterId?: string;
}

export type MatterDetailContextType = 'reconciliation' | 'empty' | 'non-empty';
