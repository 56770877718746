import { IMattersFilterBy } from '../../models';
import * as actions from '../actions/matter-filters';

export interface State {
  onClose: boolean;
  filterBy: IMattersFilterBy;
}

export const initialState: State = {
  onClose: false,
  filterBy: null,
};

export const reducer = (state = initialState, action: actions.MatterFiltersModalActions): State => {
  switch (action.type) {
    case actions.MATTER_FILTERS_MODAL_ON_OPEN: {
      return {
        ...state,
        onClose: false,
        filterBy: null,
      };
    }

    case actions.MATTER_FILTERS_MODAL_ON_CLOSE: {
      return {
        ...state,
        onClose: true,
        filterBy: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const selectOnClose = (state: State) => state.onClose;
export const selectFilterBy = (state: State) => state.filterBy;
