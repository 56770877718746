import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { LicenseManager } from '@ag-grid-enterprise/core';

import { environment } from '@env/environment';

import { AppModule } from '@app/app.module';
import { initAuthAgent } from '@app/core/services';

import '@ag-grid-enterprise/row-grouping';

/**
 * Initialise our application.
 * In production, this is called after Kasada SDK is loaded.
 */
async function init() {
  const aggridLicense =
    'Using_this_{AG_Grid}_Enterprise_key_{AG-057108}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{LEGAL_SOFTWARE_DEVELOPMENTS_PTY._LIMITED}_is_granted_a_{Multiple_Applications}_Developer_License_for_{14}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{16}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_June_2025}____[v3]_[01]_MTc1MDI4NzYwMDAwMA==c39623affb68db467116efdeb8676cb7';
  LicenseManager.setLicenseKey(aggridLicense);

  await initAuthAgent();

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

/**
 * Initialise Kasada, and then call our main application init.
 */
async function initWithKasada() {
  document.addEventListener('kpsdk-load', function () {
    // Kasada configuration
    window.KPSDK.configure(environment.config.ksd.configurations);

    // Now call main init
    init();
  });

  // Append Kasada entrypoint script
  const script = document.createElement('script');
  script.src = `${environment.config.endpoint.docs}/149e9513-01fa-4fb0-aad4-566afd725d1b/2d206a39-8ed7-437e-a3be-862e0f06eea3/p.js`;

  document.head.appendChild(script);
}

document.addEventListener('DOMContentLoaded', () => {
  if (environment.config.ksd.enabled && 'document' in globalThis) {
    initWithKasada();
  } else {
    init();
  }
});
