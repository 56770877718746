import { of as observableOf, Observable, throwError } from 'rxjs';
// core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, map as pipeMap } from 'rxjs/operators';

import { BaseService, DialogService, UiUtilsService } from '@app/shared/services';
import { ISchemaApiResponse, IDialogOptions, PrecedentContentSearchResult, FirmDetails } from '@app/shared/models';
import { environment } from '@env/environment';
import { IPrecedent, INewFolderRequest, ICustomPrecedent, PrecedentType, IContentGatewayPrecedentVersionInfo } from '../../models';
import { IDuplicatePrecedentParams, IPrecedentVersion } from '@app/features/+precedent/models';
import { ConfigService, PermissionsService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { isEmptyArray, isEmptyValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class PrecedentService extends BaseService {
  constructor(
    private http: HttpClient,
    private _configService: ConfigService,
    private _permissionsSvc: PermissionsService,
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateService,
    private _uiUtilsSvc: UiUtilsService
  ) {
    super();
  }

  /** Auxiliary precedent functions **/
  createDocumentFromReadonlyPrecedent(
    precedentId: string,
    matterId: string,
    folderId: string = null
  ): Observable<void> {
    const url = this.urlJoin(this.automationPath, '/api/v1/automation/documents');
    const message = { precedentId, matterId, folderId };
    return this.http.post<void>(url, message);
  }

  deletePrecedent(precedentId: string): Observable<void> {
    const url = this.urlJoin(this.apiPath, `api/v1/customprecedents/${precedentId}/delete`);
    return this.http.delete<void>(url);
  }

  createNewFolder(newFolderReq: INewFolderRequest) {
    if (!newFolderReq.matterTypeId && !newFolderReq.parentPrecedentId) {
      console.warn('Warning, no matterTypeId or parentPrecedentId provided for new folder creation.');
    }

    const url = this.urlJoin(this.apiPath, '/api/v1/customprecedents/folder');
    return this.http.post(url, newFolderReq);
  }

  undeletePrecedent(precedentId: string): Observable<void> {
    const url = this.urlJoin(this.apiPath, `api/v1/customprecedents/undelete`);
    const message = {
      basePrecedentId: precedentId,
      reason: '',
    };
    return this.http.post<void>(url, message);
  }

  duplicatePrecedent(params: IDuplicatePrecedentParams): Observable<void> {
    const { precedent, duplicateName, duplicateId } = params;
    const url = this.urlJoin(this.apiPath, `api/v1/customprecedents/duplicate`);
    const message = {
      id: duplicateId,
      baseId: precedent.baseId? precedent.baseId: precedent.id,
      fromStandard: precedent.precedentType?.includes('Standard') && !precedent.isFirmPrecedent,
      reason: '',
      name: duplicateName,
      lastVersionId: !!precedent.documentVersions ? precedent.documentVersions.latestDocumentVersionId : '',
    };
    return this.http.post<void>(url, message);
  }

  getPrecedentVersions(precedentId: string): Observable<IContentGatewayPrecedentVersionInfo> {
    const url = this.urlJoin(this.contentPath, `api/v1/precedents/${precedentId}`);
    return this.http.get<IContentGatewayPrecedentVersionInfo>(url);
  }

  getLatestPrecedentFile(precedentId: string): Observable<any> {
    const url = this.urlJoin(this.apiPath, `api/v2/precedents/files/${precedentId}/latest`);
    return this.http.get(url, {
      responseType: 'blob'
    });
  }
  /** End - Auxiliary precedent functions **/

  /**
   * Recursively append children to a precedent to make a tree
   */
  appendChildren(precedents: IPrecedent[], precedentId: string, children: IPrecedent[]): IPrecedent[] {
    return (precedents || []).map((precedent: IPrecedent) => {
      if (precedent.id === precedentId) {
        // Construct a uiPath regardless of schema's path and shortcutId. Simplify node-tracing on client-side.
        return {
          ...precedent,
          children: (children || []).map((c: IPrecedent) => ({
            ...c,
            uiPath: `${precedent.uiPath || '/'}${precedentId || ''}/`,
          })),
        };
      } else {
        return isEmptyArray(precedent.children)
          ? precedent
          : { ...precedent, children: this.appendChildren(precedent.children, precedentId, children) };
      }
    });
  }

  /**
   * Get a specific precedent with more detailed information
   *
   * @param id of the precedent
   * @return an observable of a specific precedent
   */
  getPrecedent(id: string): Observable<IPrecedent> {
    const url = this.urlJoin(this.contentPath, '/api/v1/precedents', id);
    return this.http.get(url).pipe(
      pipeMap((resp: any) => {
        if (resp) {
          const { precedentId, ...others } = resp;

          return {
            ...others,
            id: precedentId,
          } as IPrecedent;
        }

        return undefined;
      })
    );
  }

  /**
   * Enrich a precedent in case it is a shortcut
   *
   * @param precedent to be enriched
   */
  getEnrichedPrecedent(precedent: IPrecedent): Observable<IPrecedent> {
    if (!!precedent.shortcutId) {
      return this.getPrecedent(precedent.shortcutId).pipe(
        pipeMap((originalPrecedent: IPrecedent) => ({
          ...precedent,
          originInDesktop: originalPrecedent.openInDesktop,
          precedentId: originalPrecedent.id,
          precedentType:
            originalPrecedent.precedentType === PrecedentType.Customized
              ? originalPrecedent.precedentType
              : precedent.precedentType,
        }))
      );
    }
    return observableOf(precedent);
  }

  /**
   * Get nested precedents inside a folder
   *
   * @param matterTypeId
   * @param id of precedent
   * @returns
   */
  getChildPrecedents(matterTypeId: string, id: string, resolveShortcuts = false): Observable<IPrecedent[]> {
    const url = this.urlJoin(this.contentPath, '/api/v1/precedents/', id, `/children`);
    return this.http.get(url).pipe(
      pipeMap((resp: any) => {
        if (resp) {
          return resp.map((r) => {
            if (r) {
              const { precedentId, ...others } = r;
              return {
                ...others,
                id: precedentId,
              } as IPrecedent;
            }
            return undefined;
          });
        }

        return [];
      })
    );
  }

  /**
   * Get a list of Class Precedents
   *
   * @param className
   * @returns
   */
  getPrecedentsByClass(className: string): Observable<IPrecedent[]> {
    const url = this.urlJoin(this.contentPath, `/api/v1/precedents/search`);
    const body = { class: className };
    return this.http.post(url, body).pipe(pipeMap((res) => this.mapContentApiResponseToIPrecedent(res)));
  }

  getCustomPrecedent(): Observable<ICustomPrecedent[]> {
    const url = `${this.apiPath}/api/v1/containers`;
    return this.http.get<ICustomPrecedent[]>(url);
  }

  getLetterPrecedent(): Observable<ICustomPrecedent> {
    return this.getCustomPrecedent().pipe(
      pipeMap((customPrecedents) => {
        const letterId = environment.config.precedent.letterContainerId;

        let precedent: ICustomPrecedent = customPrecedents.find(
          (p) => p.basePrecedentId === letterId || p.id === letterId
        );

        if (!precedent) {
          precedent = customPrecedents.find((p) => p.name === 'Letter');
        }

        if (!precedent) {
          throwError(() =>  'Could not find letter precedent.');
        }

        return precedent;
      })
    );
  }

  searchPrecedentsByMatterTypeId(
    parentId: string,
    searchText: string,
    state: string
  ): Observable<Partial<ISchemaApiResponse<IPrecedent[]>>> {
    return isEmptyValue(searchText)
      ? this.getTopLevelPrecedents(parentId)
      : this.getFilteredPrecedents(searchText, parentId, state);
  }

  searchPrecedentsByAll(searchText: string, state: string): Observable<Partial<ISchemaApiResponse<IPrecedent[]>>> {
    return this.searchAllPrecedents(searchText, state);
  }

  searchPrecedentContent(
    searchText: string,
    type: string,
    page: number,
    limit: number,
    highlights: boolean = true
  ): Observable<ISchemaApiResponse<PrecedentContentSearchResult[]>> {
    return this.searchInContent(searchText, type, page, limit, highlights);
  }

  searchPrecedentContentNoDeleted(
    searchText: string,
    type: string,
    page: number,
    limit: number,
    highlights: boolean = true
  ): Observable<ISchemaApiResponse<PrecedentContentSearchResult[]>> {
    return this.searchInContent(searchText, type, page, limit, highlights).pipe(
      map((response: ISchemaApiResponse<PrecedentContentSearchResult[]>) => {
        const filteredResults = response.data.filter(item => item.deleteCode === 0);
        return {
          ...response,
          data: filteredResults
        };
      })
    );
  }

  showByLawyersWarning(precedent: IPrecedent, isCommentary: boolean = false) {
    const closeConfirmed = (confirmed: boolean) => {
      if (confirmed === true) {
        const browserWindow = window.open(this._configService.getByLawyersUrl(), '_blank');
        if (!browserWindow || browserWindow.closed || typeof browserWindow.closed === 'undefined') {
          this._uiUtilsSvc.handlePopUpBlocked();
        }
      }
    };

    const message = isCommentary
      ? 'Commentaries are available when subscribed to the By Lawyers Guides and Precedents add on.'
      : `In order to use the precedent '${precedent.name}' you need to subscribe to 'By Lawyers Guides and Precedents'.`;

    const opts: IDialogOptions = {
      actionText: 'Learn More',
      closeText: 'Close',
      showCancel: true,
      message,
      onClose: closeConfirmed,
    };

    this._dialogSvc.warning(opts);
  }

  showOfficeOnlineOptionForPrecedent(data: { precedent: IPrecedent; isRecurringMatter: boolean }): boolean {
    const { precedent, isRecurringMatter } = data;
    const unSupportedDocTypes = ['pdf'];
    return (
      !isRecurringMatter &&
      !this._permissionsSvc.isContentReadOnly &&
      this._permissionsSvc.hasMatterWrite &&
      !!precedent &&
      !precedent.openInDesktop &&
      !unSupportedDocTypes.includes(precedent.type || precedent.documentType)
    );
  }

  checkPrecedentAccessibility(params: { precedent: IPrecedent; firmDetails: FirmDetails }): boolean {
    const { precedent, firmDetails } = params;
    if (!this.isValidPrecedent(precedent)) {
      this._dialogSvc.warning({
        message: `Document not supported. Please use LEAP Desktop to open.`,
      });
      return false;
    }

    if (!!firmDetails && !firmDetails.byLawyers && this.isByLawyersPrecedent(precedent)) {
      this.showByLawyersWarning(precedent);
      return false;
    }

    return true;
  }

  getUnsupportedCreatePrecedentList(): { containerIds: string[]; extensions: string[] } {
    const blackListedContainers = ['a8623549-2f86-476c-91b4-ab8a77d04e36'];
    const excludeExtension = ['xlsx', 'xls'];
    return {
      containerIds: [...blackListedContainers],
      extensions: [...excludeExtension],
    };
  }

  private isValidPrecedent(precedent: IPrecedent): boolean {
    return !!precedent && (precedent.type === 'docx' || precedent.type === 'doc' || precedent.type === 'pdf');
  }

  private isByLawyersPrecedent(precedent: IPrecedent): boolean {
    if (precedent.code === null) {
      return false;
    }
    const split = precedent.code.split(':', 1);
    return !!split && split.length !== 0 && split[0] === 'BLFL';
  }

  private getTopLevelPrecedents(matterTypeId: string): Observable<ISchemaApiResponse<IPrecedent[]>> {
    const url = this.urlJoin(this.contentPath, `/api/v1/mattertypes/${matterTypeId}/precedents`);
    return this.http.get<IPrecedent[]>(url).pipe(
      pipeMap((resp) => {
        if (!!resp) {
          const data = resp.map((r: any) => {
            const { precedentId, ...others } = r;
            return {
              ...others,
              id: precedentId,
            } as IPrecedent;
          });

          return {
            data,
            type: 'Precedents',
            limit: 1000,
            page: 1,
            totalPages: 1,
            totalRows: data.length,
          } as ISchemaApiResponse<IPrecedent[]>;
        }

        return {
          data: [],
          type: 'Precedents',
          limit: 1000,
          page: 1,
          totalPages: 1,
          totalRows: 0,
        } as ISchemaApiResponse<IPrecedent[]>;
      })
    );
  }

  private getFilteredPrecedents(
    searchText: string,
    parentId: string,
    state: string
  ): Observable<ISchemaApiResponse<IPrecedent[]>> {
    const url = this.urlJoin(this.contentPath, `/api/v1/precedents/search`);
    const body = { parentId, quickSearch: searchText, state };
    return this.http.post<IPrecedent[]>(url, body).pipe(
      pipeMap((res) => ({
          data: this.mapContentApiResponseToIPrecedent(res),
          type: 'Precedents',
        } as ISchemaApiResponse<IPrecedent[]>))
    );
  }

  private searchInContent(
    searchText: string,
    type: string = 'all',
    page = 1,
    limit = 1000,
    highlights: boolean = true
  ): Observable<ISchemaApiResponse<PrecedentContentSearchResult[]>> {
    //todo: new endpoint* - dont need to migrate as not supported in new api.
    const url = this.urlJoin(
      this.schemaPath,
      '/api/v3/precedents/search',
      `?keywords=${searchText}`,
      `&type=${type}&page=${page}&limit=${limit}`,
      `&highlights=${highlights}`
    );

    return this.http.get<ISchemaApiResponse<PrecedentContentSearchResult[]>>(url);
  }
  /**
   * Search all precedents matching a specified keyword. Shortcuts are excluded. Result is a flat list for the
   * given page number.
   */
  private searchAllPrecedents(searchText: string, state: string): Observable<ISchemaApiResponse<IPrecedent[]>> {
    const url = this.urlJoin(this.contentPath, `/api/v1/precedents/search`);
    const body = { quickSearch: searchText, state };
    return this.http.post<IPrecedent[]>(url, body).pipe(
      pipeMap((res) => ({
          data: this.mapContentApiResponseToIPrecedent(res),
          type: 'Precedents',
        } as ISchemaApiResponse<IPrecedent[]>))
    );
  }

  private mapContentApiResponseToIPrecedent = (res: any) => {
    const data = res.map((r) => {
      const {
        precedentId,
        parentId,
        matterTypeId,
        shortcutId,
        class: rClass,
        code,
        type,
        formNumber,
        sortOrder,
        url: rUrl,
        hide,
        openInDesktop,
        deleteCode,
        latestDocVersion,
        precedentType,
        name,
      } = r as any;

      return {
        id: precedentId,
        name,
        parentId,
        matterTypeId,
        shortcutId,
        class: rClass || '',
        code,
        type,
        formNumber,
        sortOrder,
        url: rUrl || '',
        hide,
        openInDesktop,
        deleteCode,
        isCustomisedPrecedent: !precedentType.includes('Standard'),
        documentVersions: { latestDocumentVersionId: latestDocVersion, documentVersion: [] },

        // ** below are not return from current content api **
        // customPrecedentId: '',
        // isFirmPrecedent: false,
        // documentType: type || '',
        // transferType: '',
        // imageName: '',
        // container: '',
        // tableFieldsExist: false,
        // autoFieldsExist: false,
        // childCount: 0,
        // children: null,
        // originInDesktop: false,
        // path: null,
        // uiPath: null,
      } as IPrecedent;
    });

    return data;
  };
}
