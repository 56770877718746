import { Action } from '@ngrx/store';
import { IMattersFilterBy } from '@app/features/+matter-list/models';

export const MATTER_FILTERS_MODAL_ON_CLOSE = '[Matter Filters Modal] on close event';
export const MATTER_FILTERS_MODAL_ON_OPEN = '[Matter Filters Modal] on open event';

export class MatterFiltersModalOnClose implements Action {
  readonly type = MATTER_FILTERS_MODAL_ON_CLOSE;
  constructor(public payload: IMattersFilterBy) {}
}

export class MatterFiltersModalOnOpen implements Action {
  readonly type = MATTER_FILTERS_MODAL_ON_OPEN;
  constructor(public payload: any) {}
}

export type MatterFiltersModalActions = MatterFiltersModalOnClose | MatterFiltersModalOnOpen;
