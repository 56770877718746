import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export const handleHttpError = (error: HttpErrorResponse, params: IErrorHandlingParams): Observable<any> => {
  const { operation, url, strategy } = params;
  const log = params.log || console.log;
  const errMsg = `Error in ${operation} from ${url}`;

  log(`${errMsg}:`, error);
  // ng13 has issue with instanceOf HttpErrorResponse.
  if (error?.name === 'HttpErrorResponse') {
    // you could extract more info about the error if you want, e.g.:
    log(`status: ${error.status}, ${error.statusText}`);
    return !!strategy[error.status] ? observableOf(strategy[error.status]) : observableThrowError(errMsg);
  }
  return observableThrowError(errMsg);
};

interface IErrorHandlingParams {
  operation: string;
  url: string;
  strategy: { [key: number]: any };
  log?: (...args: any[]) => void;
}
