import { Action } from '@ngrx/store';

import { IMatterListResponseSchema, MatterListMetaInfo } from '../../../models';

export const MATTERS_SAVE_DB_START = '[Matters save to db] start';
export const MATTERS_SAVE_DB_SUCCESS = '[Matters save to db] success';

export const SET_META = '[Matter list meta] save meta inform into ngrx store';

export const MATTER_LIST_META_SAVE_DB_START = '[Matter list meta save to db] start';
export const MATTER_LIST_META_SAVE_DB_SUCCESS = '[Matter list meta save to db] success';
export const MATTER_LIST_META_SAVE_DB_FAILURE = '[Matter list meta save to db] failure';

export class MatterSaveDbStart implements Action {
  readonly type = MATTERS_SAVE_DB_START;

  constructor(public payload: IMatterListResponseSchema) {}
}

export class MatterSaveDbSuccess implements Action {
  readonly type = MATTERS_SAVE_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class MatterListMetaSaveDbStart implements Action {
  readonly type = MATTER_LIST_META_SAVE_DB_START;

  constructor(public payload: Partial<MatterListMetaInfo>) {}
}

export class MatterListMetaSaveDbSuccess implements Action {
  readonly type = MATTER_LIST_META_SAVE_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class MatterListMetaSaveDbFailure implements Action {
  readonly type = MATTER_LIST_META_SAVE_DB_FAILURE;

  constructor(public payload: any) {}
}

export class SetMeta implements Action {
  readonly type = SET_META;

  constructor(public payload: Partial<MatterListMetaInfo>) {}
}

export type MatterListDbActions =
  | MatterSaveDbStart
  | MatterSaveDbSuccess
  | MatterListMetaSaveDbStart
  | MatterListMetaSaveDbSuccess
  | MatterListMetaSaveDbFailure
  | SetMeta;
