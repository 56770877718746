import { IMenuItem } from '@app/shared/models';
import { isFolder } from './correspondence.function';
import { getObjValue, isNumber } from '../../../../server/modules/shared/functions/common-util.functions';

export const canSelectFile = <T>(file: T): boolean => (
  isFolder(file) ||
  getObjValue(file, 'type', '') === 'docx' ||
  getObjValue(file, 'type', '') === 'pdf' ||
  getObjValue(file, 'type', '') === 'doc'
);

export const transformContextMenuActions = <T = unknown>(actions: Partial<IMenuItem<T>>[]): IMenuItem<T>[] => {
  const defaultMenuItem = {
    execute: () => {},
    divider: false,
    enabled: () => true,
    text: () => '',
    visible: () => true,
  };
  return actions?.map((action: IMenuItem<T>) => ({ ...defaultMenuItem, ...action })) || [];
};

export const toNumber = (num: string | number): number => {
  num = isNumber(num) ? (num as number) : parseFloat(num as string);
  return isNumber(num) ? num : 0;
};

export const appendComma = (s: string): string => {
  if (!s) {
    return '';
  }
  const blacklist = [',', '.', '?', '!', ';', ':'];
  if (blacklist.find((c) => s[s.length - 1] === c)) {
    return s;
  }

  return `${s},`;
};

export const cropText = (s: string, length: number): string => {
  if (!s) {
    return '';
  }
  s = s.trim();
  if (!s) {
    return '';
  }
  if (s.length < length) {
    return s;
  }
  if (length < 3) {
    return s.substr(0, length);
  }
  return s.substr(0, length - 3) + '...';
};

export const base64ToArrayBuffer = (base64) => {
  const binaryString = atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(new ArrayBuffer(binaryLen));
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const createAndDownloadBlobFile = (data: { body: any; fileName: string; ext: string }): void => {
  const { body, fileName: name, ext } = data;
  const blob = new Blob([body]);
  const fileName = `${name}.${ext}`;
  const navigatorAny = navigator as any;
  if (navigatorAny.msSaveBlob) {
    navigatorAny.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  return;
};

export const compareValues = (key: string, order: 'asc' | 'desc' = 'asc') => (a, b) => {
  if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
    // property doesn't exist on either object
    return 0;
  }

  if (!a[key]) {
    return order === 'asc' ? -1 : 1;
  }

  if (!b[key]) {
    return order === 'asc' ? 1 : -1;
  }

  const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
  const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return order === 'desc' ? comparison * -1 : comparison;
};

export const capitalize = (s: string, pristine?: boolean) => typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + (pristine ? s.slice(1) : s.slice(1).toLowerCase());
