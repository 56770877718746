export interface ExtensionHelper {
  title: string;
  types: string[];
}

export const WordDocumentExtension: ExtensionHelper = {
  title: 'Word Document',
  types: ['doc', 'docx', 'msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document'],
};

export const ImageExtension: ExtensionHelper = {
  title: 'Image',
  types: ['tiff', 'tif', 'jpg', 'gif', 'png', 'jpeg', 'bmp'],
};

export const AudioExtension: ExtensionHelper = {
  title: 'Audio',
  types: ['mp3', 'wav', 'flac', 'aac'],
};

export const VideoExtension: ExtensionHelper = {
  title: 'Video',
  types: ['mp4', 'mov', 'avi']
};

export const EmailExtension: ExtensionHelper = {
  title: 'Email',
  types: ['msg', 'eml'],
};

export const ExcelExtension: ExtensionHelper = {
  title: 'Excel',
  types: ['xls', 'xlsx', 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
};

export const PdfExtension: ExtensionHelper = {
  title: 'PDF Document',
  types: ['pdf'],
};

export const CommentExtension: ExtensionHelper = {
  title: 'Comment',
  types: ['html'],
};

export const PDFConversionInvalidExtensions = [
  ...AudioExtension.types,
  ...VideoExtension.types,
  ...ImageExtension.types
];
