export interface IRate {
  id: number;
  name: string;
  rate: number;
  display: string;
}

export interface IStaff {
  staffId: string;
  userId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  initials: string;
  email: string;
  phone: string;
  rates: IRate[];
  __id: string;
  __className: string;
  branch: string;
  fullName: string;
  legalFullName: string;
  qualifications: string;
  title: string;
  fax: string;
  immigLicense: string;
  certificates: string;
  extension: string;
  mobile: string;
  firmReference: string;
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
  rate5: number;
  rate6: number;
  deleteCode?: number;
}

export interface Staff {
  __id: string;
  __className: string;
  deleteCode: number;
  userId: string;
  branch: string;
  firstName: string;
  lastName: string;
  initials: string;
  fullName: string;
  legalFullName: string;
  title: string;
  email: string;
  mobile: string;
  firmReference: string;
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
  rate5: number;
  rate6: number;
  __tableId: string;
  StaffSecurities: StaffSecurityItem[];
  status?: number; // status = 0 means the staff is in current staff category; 1 = history staff
  officePaymentLimit?: number;
  trustPaymentLimit?: number;
  autoTime?: boolean;
}

export interface StaffSecurityItem {
  SecurityId: number;
}

export interface StaffSecurity {
  StaffSecurities: StaffSecurityItem[];
}

export interface SuggestedStaff extends Staff {
  roles?: [string];
}

export enum StaffSecurityIds {
  VIEW_OTHER_STAFF_MEMBERS_TIME_ENTRIES = 2012,
}

export interface StaffPaymentLimit {
  OfficePaymentLimit?: number;
  TrustPaymentLimit?: number;
}
