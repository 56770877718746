import { Action } from '@ngrx/store';
import { AdvancedSearchData } from '@app/features/+matter-list/models';

export const ADVANCED_SEARCH_MODAL_ON_CLOSE = '[Advanced Search Modal] on close event';
export const ADVANCED_SEARCH_MODAL_ON_OPEN = '[Advanced Search Modal] on open event';

export class AdvancedSearchModalOnClose implements Action {
  readonly type = ADVANCED_SEARCH_MODAL_ON_CLOSE;
  constructor(public payload: AdvancedSearchData) {}
}

export class AdvancedSearchModalOnOpen implements Action {
  readonly type = ADVANCED_SEARCH_MODAL_ON_OPEN;
  constructor(public payload: AdvancedSearchData) {}
}

export type AdvancedSearchModalActions = AdvancedSearchModalOnClose | AdvancedSearchModalOnOpen;
