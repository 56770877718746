import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpEventType, HttpRequest, HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sc-file-download-progress',
  templateUrl: './file-download-progress.component.html',
})
export class FileDownloadProgressComponent implements OnInit, OnDestroy {
  @Input()
  url: string;
  @Input()
  fileName: string;
  @Input()
  fileNameOnly: string;
  @Input()
  stop: Subject<boolean>;
  @Input()
  downloadAsFile: boolean;
  @Input()
  token: string;
  @Input()
  windowDownload: boolean;
  @Input()
  set tag(value: string) {
    this._tag = value;

    if (this.windowDownload) {
      this.browserDownload();
    } else {
      this.download();
    }
  }
  get tag() {
    return this._tag;
  }
  @Output()
  done = new EventEmitter<boolean>();
  @Output()
  onDownloadError = new EventEmitter<any>();
  @Output()
  blob = new EventEmitter<any>();

  loadingProgress: number;

  private _tag: string;

  constructor(private _http: HttpClient) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.stop.next(true);
  }

  browserDownload(): void {
    if (!this.url) {
      return;
    }

    const fileNameWithoutExtension = encodeURIComponent(this.fileNameOnly);
    const format = this.url.indexOf('format=') >= 0 ? '&' : '?';
    const fromAws = this.url.indexOf('.amazonaws.') >= 0;
    window.location.href = fromAws
      ? this.url
      : `${this.url}${format}access_token=${this.token}&downloadFile=true&downloadFileName=${fileNameWithoutExtension}`;

    this.done.emit(true);
  }

  download(): void {
    const req = new HttpRequest('GET', this.url, {
      responseType: 'blob',
      reportProgress: true,
    });

    let totalSize = 0;
    this._http
      .request(req)
      .pipe(takeUntil(this.stop))
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.ResponseHeader) {
            totalSize = +event.headers.get('content-length');
          }

          if (event.type === HttpEventType.DownloadProgress) {
            const percentage = !totalSize ? 0 : (event.loaded / totalSize) * 100.0;
            this.loadingProgress = percentage < 100.0 ? percentage : 100;
          }

          if (event.type === HttpEventType.Response) {
            const blob = new Blob([event.body as any]);
            if (this.downloadAsFile) {
              FileSaver.saveAs(blob, this.fileName);
            }

            this.blob.emit([event.body]);
            this.done.emit(true);
            this.stop.next(true);
          }
        },
        (error) => this.onDownloadError.emit(error)
      );
  }
}
