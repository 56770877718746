import { Observable, of, Subject, throwError, timer as observableTimer } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { IEmailData } from 'app/shared/models';
import { EmailService, LogService } from 'app/core/services';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';

import { DeleteAttachment, DeleteAttachmentFailure, DeleteAttachmentSuccess } from '../actions';
import { AttachmentService } from 'app/features/+email/services';
import * as emailActions from '../actions/email.actions';
import * as emailDraftActions from '../actions/draft-email.actions';
import * as contactActions from '@app/features/+matter-details/store/actions';
import {
  Attachment,
  createEmail,
  ICreateEmailOptions,
  RefreshAttachmentsRequest,
  toRefreshAttachmentsRequest,
  UpdateEmailResponse,
} from '../../models';
import { selectAttachments, selectDraft, selectEmail, selectNewWin } from '../selectors';
import { RefreshAttachmentsFailure } from 'app/features/+email/store/actions';
import { selectCurrentMatter, selectCurrentMatterId } from '@app/core/store';
import { IMatterListEntry } from 'app/features/+matter-list/models';
import { IEmail, toEmailDTO } from 'app/features/+email/models/email.model';
import { FileItem } from 'ng2-file-upload';
import { AuthService, FormStorageService, PlatformService } from '@app/core/services';
import { DialogService, OfflineLauncherService } from '@app/shared/services';
import * as appActions from '../../../../core/store/actions';
import * as createPDFActions from '@app/features/+create-pdf/store/actions/createpdf.actions';
import { AppApiService } from '@app/core/api';
import { selectAllContacts } from '@app/features/+matter-details/store';
import { selectSelectedFolderId } from '@app/features/+correspondence/store/selectors';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { isEmptyArray, isEmptyValue } from '@server/modules/shared/functions/common-util.functions';
import * as RouterActions from '@app/core/store/actions/router.action';
import { selectRouterLayers } from '@app/core/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class EmailEffects {
  destroy$: Subject<boolean> = new Subject<boolean>();
  formUiKey = '__EMAIL__FORM__UI';

  /* eslint-disable  */
  // create draft
  createDraft$ = createEffect(() => this.actions$.pipe(
    ofType(emailDraftActions.CREATE_DRAFT),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatter)),
      this.store.pipe(select(selectAllContacts)),
      this.store.pipe(select(selectDraft)),
      this.store.pipe(select(selectSelectedFolderId)),
      (action, matter, contacts, emailDraft, selectedfolderId) => ({
        matter,
        contacts,
        emailDraft,
        selectedfolderId,
      })
    ),
    mergeMap((data) => {
      const { matter, contacts, emailDraft, selectedfolderId } = data;
      const draft: ICreateEmailOptions = Object.assign({}, emailDraft);
      const isContactInStore = !isEmptyArray(contacts);

      return this._authSvc.userDetails().then((user) => {
        if (!isContactInStore) {
          this.store.dispatch(new contactActions.GetContactStart(user.externalUser.email));
        }
        return { matter, user, draft, selectedfolderId };
      });
    }),
    switchMap(({ matter, user, draft, selectedfolderId }) => {
      this._log.info('matter: ', matter, 'user: ', user);
      const email = this.createDraftEmail(matter, user, draft, selectedfolderId);
      this._log.info('createDraft$: email - ', email);
      return this._emailService.createEmail(toEmailDTO(email)).pipe(
        mergeMap((val) => [new emailDraftActions.CreateDraftSuccess({ ...email, id: val.id })]),
        catchError((err) => of(new emailDraftActions.CreateDraftFailure(err)))
      );
    })
  ));

  createDraftSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(emailDraftActions.CREATE_DRAFT_SUCCESS),
    tap(() => this.destroy$.next(true)),
    mergeMap(() => [new emailActions.DeHydrateEmailForm(null)])
  ));

  createDraftFailure$ = createEffect(() => this.actions$.pipe(
    ofType<emailDraftActions.CreateDraftFailure>(emailDraftActions.CREATE_DRAFT_FAILURE),
    mergeMap((err) => {
      this._appApiSvc.clearCurrentModal();
      return throwError(
        new SiriusError({
          type: 'error',
          title: 'Failure',
          message: 'Unable to create email draft.',
        })
      );
    })
  ), { dispatch: false });

  // send email
  sendEmail$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.SEND_EMAIL),
    withLatestFrom(
      this.store.pipe(select(selectDraft)),
      this.store.pipe(select(selectEmail)),
      (action, draft, email) => ({
        draft,
        email,
      })
    ),
    switchMap(({ draft, email }) => {
      const emailToSend = { ...draft, ...email } as IEmail;
      this._log.info('sendEmail$ ', emailToSend);
      return this._emailService.updateEmail(emailToSend).pipe(
        mergeMap((update: UpdateEmailResponse) => {
          this._log.info('update from updateEmail: ', update);
          return this._emailService
            .sendEmail(update.id)
            .pipe(mergeMap(() => [new emailActions.SendEmailSuccess(null)]));
        }),
        catchError((error) => {
          if (error.status === 401) {
            this._appApiSvc.reauthenticateUser();
          }
          return [new emailActions.SendEmailFailure(error)];
        })
      );
    })
  ));

  sendEmailFailure$ = createEffect(() => this.actions$.pipe(
    ofType<emailActions.SendEmailFailure>(emailActions.SEND_EMAIL_FAILURE),
    tap((action) => {
      const errors = action.payload.error.error;
      const title = this._translateSvc.instant('Email.New.Send.Error.Title');
      let message = this._translateSvc.instant('Email.New.Send.Error.Message');
      if (!isEmptyValue(errors)) {
        message = `${message} ${errors.join('. ')}`;
      }
      this._dialogSvc.error({
        title,
        message
      });
    })
  ), {dispatch: false})

  // discard draft
  discardDraft$ = createEffect(() => this.actions$.pipe(
    ofType(emailDraftActions.DISCARD_DRAFT),
    withLatestFrom(this.store.pipe(select(selectDraft)), (action, draft) => draft),
    mergeMap((draft) => {
      this._log.info('discardDraft$ ', draft);
      if (!draft) {
        return of(new emailDraftActions.DiscardDraftSuccess(null));
      }
      return this._emailService.deleteEmail(draft.id).pipe(
        mergeMap(() => [new emailDraftActions.DiscardDraftSuccess(null), new createPDFActions.ClosePDFModal(null)]),
        catchError((error) => of(new emailDraftActions.DiscardDraftFailure(error)))
      );
    })
  ));

  // close email
  closeEmail$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.CLOSE_EMAIL),
    tap(() => this.destroy$.next(true)),
    filter(() => this._ps.isBrowser),
    map(() => this._formStorageSvc.clean()),
    exhaustMap(() => [new emailActions.CloseEmailSuccess(true)]),
    catchError((error) => of(new emailActions.CloseEmailFailure(error)))
  ));

  // add attachments
  addAttachments$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.ADD_ATTACHMENTS),
    map((action: emailActions.AddAttachments) => action.payload),
    mergeMap((files: FileItem[]) => {
      return this._attachmentSvc.createAttachments(files).pipe(
        mergeMap((attachments: Attachment[]) => [new emailActions.AddAttachmentsSuccess(attachments)]),
        catchError((error) => of(new emailActions.AddAttachmentsFailure(error)))
      );
    })
  ));

  // delete attachment
  delAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.DELETE_ATTACHMENT),
    map((action: DeleteAttachment) => action.payload),
    mergeMap((attachment: Attachment) => {
      return this._attachmentSvc.deleteAttachment(attachment.id).pipe(
        mergeMap(() => [new DeleteAttachmentSuccess(attachment)]),
        catchError((error) => of(new DeleteAttachmentFailure(error)))
      );
    })
  ));

  delAttachmentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.DELETE_ATTACHMENT_SUCCESS),
    tap(() => this.destroy$.next(true)),
    mergeMap(() => [new emailActions.RefreshAttachments([])])
  ));

  addAttachmentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.ADD_ATTACHMENTS_SUCCESS),
    tap(() => this.destroy$.next(null)),
    mergeMap(() => [new emailActions.RefreshAttachments([])])
  ));

  refreshAttachments$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.REFRESH_ATTACHMENTS),
    withLatestFrom(this.store.pipe(select(selectAttachments)), (action, attachments) => attachments),
    exhaustMap((attachments: Attachment[]) => {
      this._log.info('attachments: ', attachments);
      return this.setupRefreshAttachment(attachments).pipe(
        mergeMap(() => [new emailActions.RefreshAttachmentsSuccess(attachments)]),
        catchError((error) => of(new RefreshAttachmentsFailure(error)))
      );
    })
  ));

  getEmailSubject$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.GET_EMAIL_SUBJ_START),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatterId)),
      (action: emailActions.GetEmailSubject, matterId) => ({ action, matterId })
    ),
    exhaustMap(({ action, matterId }) => {
      this._log.info('selected matterId: ', matterId);

      return this._emailService.getEmailSubject(matterId, action.payload).pipe(
        mergeMap((subject) => [new emailActions.GetEmailSubjectSuccess(subject)]),
        catchError((error) => of(new emailActions.GetEmailSubjectFailure(error)))
      );
    })
  ));

  /* eslint-disable  */
  createDraftInOutlook$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<emailDraftActions.CreateDraftInOutlook>(emailDraftActions.CREATE_DRAFT_IN_OUTLOOK),
    switchMap((action) => {
      const emailData = {
        subject: action.payload.subject,
        bcc: action.payload.bcc,
        attachments: action.payload.attachments
          ? [
              ...action.payload.attachments.map((a) => {
                const ext = this.extractExtension(a.name);
                const name = a.name.replace(`.${ext}`, '');
                return { ...a, name, ext } as Attachment;
              }),
            ]
          : [],
        body: action.payload.body,
        cc: action.payload.cc,
        to: action.payload.to,
      } as IEmailData;

      return this._offlineLauncherSvc.createNewEmailTicket({ emailData });
    }),
    mergeMap(() => [
      new RouterActions.ClearCurrentModal()
    ]),
    catchError((error) => of(new emailDraftActions.CreateDraftInOutlookFailure(error)))
  ));
  /* eslint-enable */


  closeAutomationDialog$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.AutomationActionTypes.CLOSE_AUTOMATION_DIALOG),
    withLatestFrom(this.store.pipe(select(selectNewWin)), (action, isNewWindow) => ({ isNewWindow })),
    exhaustMap(({ isNewWindow }) => {
      if (isNewWindow) {
        return [new emailActions.CloseEmail(null)];
      }
      return [];
    })
  ));

  // new window effects

  hydrateEmailForm$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.HYDRATE_EMAIL_FORM),
    filter(() => this._ps.isBrowser),
    concatMap((action: emailActions.HydrateEmailForm) => this._formStorageSvc.setFormUi(this.formUiKey, action.payload).pipe(
        mergeMap((x) => [new emailActions.HydrateEmailFormSuccess(x)]),
        catchError((error) => of(new emailActions.HydrateEmailFormFailure(error)))
      ))
  ));


  deHydrateEmailForm$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.DEHYDRATE_EMAIL_FORM),
    filter(() => this._ps.isBrowser),
    concatMap(() => this._formStorageSvc.formUi(this.formUiKey).pipe(
        mergeMap((formUi) => [new emailActions.DeHydrateEmailFormSuccess(formUi)]),
        catchError((error) => of(new emailActions.DeHydrateEmailFormFailure(error)))
      ))
  ));


  deHydrateEmailFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.DEHYDRATE_EMAIL_FORM_SUCCESS),
    filter(() => this._ps.isBrowser),
    concatMap(() => this._formStorageSvc.clean())
  ), { dispatch: false });


  closeWindow$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.CLOSE_EMAIL_SUCCESS, emailActions.SEND_EMAIL_SUCCESS, emailDraftActions.DISCARD_DRAFT_SUCCESS),
    withLatestFrom(this.store.pipe(select(selectNewWin)), (action, newWin) => newWin),
    exhaustMap((newWin) => {
      if (newWin && this._ps.isBrowser) {
        window.close();
      }
      return of(null);
    })
  ), { dispatch: false });

  // end new window effects

  // start new modal effects

  openAttachemtsModal$ = createEffect(() => this.actions$.pipe(
    ofType(emailActions.OPEN_ATTACHMENTS_MODAL),
    withLatestFrom(
      this.store.pipe(select(selectRouterLayers))
    ),
    exhaustMap(([action, layers]) => {
      this._appApiSvc.navigate({
        path: [{ outlets: { [layers[0] || 'popup']: ['email'], selectorDetail: ['createpdf'] } }],
        query: { options: 'email', overlay: true },
      });
      return of(null);
    })
  ), { dispatch: false });

  // end new modal effects

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private _log: LogService,
    private _emailService: EmailService,
    private _authSvc: AuthService,
    private _attachmentSvc: AttachmentService,
    private _formStorageSvc: FormStorageService,
    private _offlineLauncherSvc: OfflineLauncherService,
    private _ps: PlatformService,
    private _appApiSvc: AppApiService,
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateService,
  ) {
    this._log.init('email-effects');
  }

  // private
  private createDraftEmail(
    matter: IMatterListEntry,
    user: UserInfo,
    draft: Partial<ICreateEmailOptions>,
    selectedFolderId: string
  ): IEmail {
    const email = createEmail(draft);
    this._log.info('matter: ', matter, 'user: ', user);
    this._log.info(email);
    return {
      ...email,
      matterId: matter?.matterId || '',
      matterNumber: matter?.fileNumber || '',
      subject: email?.subject || matter.firstDescription,
      sender: {
        address: user.externalUser.email,
        name: `${user.externalUser.firstName} ${user.externalUser.lastName}`.trim(),
      },
      folderId: selectedFolderId,
    } as IEmail;
  }

  private setupRefreshAttachment(attachments: Attachment[]) {
    const ids = attachments.map((x) => x.id);
    const request: RefreshAttachmentsRequest = toRefreshAttachmentsRequest(ids);
    // every minute
    return observableTimer(0, 60000).pipe(
      tap(() => {
        if (ids.length === 0) {
          this.destroy$.next(true);
        }
      }),
      takeUntil(this.destroy$),
      switchMap(() => this._attachmentSvc.refreshAttachments(request))
    );
  }

  private extractExtension(fileName: string): string {
    const results: string[] = fileName.match(/\.[0-9a-zA-Z]+$/);
    return results && results.length > 0 ? results[0].replace('.', '') : '';
  }
}
