import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { LogService } from '../log/log.service';
import { AuthService } from '../auth/auth.service';
import { v4 as uuidv4 } from 'uuid';
import { PlatformService } from '../platform/platform.service';

@Injectable()
export class DexieService extends Dexie {
  constructor(private _authSvc: AuthService, private _log: LogService, private _platformSvc: PlatformService) {
    super(`Sirius_User_${_authSvc.decodedToken ? _authSvc.decodedToken.userId : 'Random_' + uuidv4()}`);
    if (this._platformSvc.isBrowser) {
      this._log.init('dexie-service');
      this.version(1).stores({
        matters: '&matterId',
        mattersMeta: '&id',
        mattersSearchMeta: '&id',
        matterDetails: '&matterId',
        correspondenceFolderMeta: '&matterId',
        cards: '&cardId',
        cardsMeta: '&id',
        persons: '&personId',
        personsMeta: '&id',
        notifications: '&id',
        notificationMeta: '&id',
        namedlist: '&id',
        appUIPreference: '&id',
        formUi: '',
      });

      this.version(2).stores({
        cards: '&cardId, shortName',
      });

      this.version(3).stores({
        namedlist: '&id, baseId',
      });

      this.version(4).stores({
        timerRecords: '&matterId',
      });
    }
  }
}
