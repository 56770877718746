import { EventBusService, LogService, PlatformService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import { Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { MatterListService, MatterListStorageService } from '../../services';
import { CreateMatterSuccessPayload, IMatterListResponseSchema } from '../../models';
import * as actions from '../actions/matter-list';
import { selectLoaded, selectMetaInfo } from '../selectors';
import { State } from '../reducers';

import { selectCardModalFormValue } from '@app/features/+card/store/selectors';
import { ESiriusEvents } from '@app/core/models';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { AppApiService } from '@app/core/api';
import { isEmptyValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class MatterListEffects {

  initListMattersStart$ = createEffect(() => this.actions$.pipe(
    ofType<actions.InitListMattersStart>(actions.INIT_LIST_MATTERS_START),
    withLatestFrom(this._store.pipe(select(selectLoaded)), (action, loaded) => loaded),
    filter((loaded) => !loaded),
    switchMap(() =>
      this._matterListStorageSvc.getMeta().pipe(
        map((meta) => (!!meta && !!meta.lastRowVer ? meta.lastRowVer : 0)),
        catchError((err) =>
           this.handleInitListMatterError() // re-throw error so that the next rxjs operator wouldn't be triggered
        )
      )
    ),
    switchMap((lastRowVer) => this._matterListSvc.getAll(lastRowVer).pipe(
        catchError((err) =>
           this.handleInitListMatterError() // re-throw error so that the next rxjs operator wouldn't be triggered
        )
      )),
    mergeMap((matterResponse: IMatterListResponseSchema) => [
      new actions.InitListMattersSuccess(matterResponse),
      new actions.MatterSaveDbStart(matterResponse),
    ])
  ));


  saveMatterListMetaToDb$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.MatterListMetaSaveDbStart>(actions.MATTER_LIST_META_SAVE_DB_START),
    filter(() => this.platformService.isBrowser),
    withLatestFrom(this._store.pipe(select(selectMetaInfo)), (action, metaInfo) => metaInfo),
    switchMap((metaInfo) =>
      this._matterListStorageSvc
        .upsertMeta(metaInfo)
        .then(() => new actions.MatterListMetaSaveDbSuccess('success'))
        .catch((error) => new actions.MatterListMetaSaveDbFailure(error))
    )
  ));


  listMatters$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.ListMattersStart>(actions.LIST_MATTERS_START),
    switchMap(() =>
      this._matterListStorageSvc.getMeta().pipe(map((meta) => (!!meta && !!meta.lastRowVer ? meta.lastRowVer : 0)))
    ),
    switchMap((lastRowVer) =>
      this._matterListSvc.getAll(lastRowVer).pipe(
        mergeMap((matterResponse: IMatterListResponseSchema) => [
          new actions.ListMattersSuccess(matterResponse),
          new actions.MatterSaveDbStart(matterResponse),
        ]),
        catchError((error) => of(new actions.ListMattersFailure(error)))
      )
    )
  ));


  saveMattersToDb$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.MatterSaveDbStart>(actions.MATTERS_SAVE_DB_START),
    filter(() => this.platformService.isBrowser),
    switchMap((action: actions.MatterSaveDbStart) =>
      this._matterListStorageSvc.upsertAll(action.payload).catch((error) => {
        // re-throw error so that the next rxjs operator wouldn't be triggered
        throw new SiriusError({
          type: 'error',
          title: 'Failure',
          message: 'unable to refresh matter list',
        });
      })
    ),
    switchMap(() => this._matterListStorageSvc.getAll().pipe(
        mergeMap((data) => {
          this._matterListStorageSvc.refreshAll(data);
          return [new actions.MatterSaveDbSuccess('success')];
        }),
        catchError((err) =>
          // re-throw error and let error-handler.service to handle this type of error
          throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'unable to refresh matter list',
            })
          )
        )
      ))
  ));


  deleteMatter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteMatterStart>(actions.DELETE_MATTER_START),
    mergeMap((action: actions.DeleteMatterStart) =>
      this._matterListSvc.delete(action.payload.matterId).pipe(
        map(() => {
          this._eventBus.emit({
            name: ESiriusEvents.ShowToastr,
            value: {
              type: 'success',
              title: 'Success',
              message: 'Matter deleted successfully.',
            },
          });
          return new actions.DeleteMatterSuccess(null);
        }),
        catchError((error) => {
          this._store.dispatch(new actions.DeleteMatterFailure(error));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to delete matter.',
            })
          );
        })
      )
    )
  ));


  createMatter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.CreateMatterStart>(actions.CREATE_MATTER_START),
    withLatestFrom(this._store.pipe(select(selectCardModalFormValue))),
    exhaustMap(([action, formValue]: [actions.CreateMatterStart, any]) => {
      const createParams = { ...action.payload, card: formValue };

      return this._matterListSvc.create(createParams).pipe(
        map((result: CreateMatterSuccessPayload) => {
          if (isEmptyValue(`${result?.fileNumber || ''}`)) {
            return new actions.CreateMatterFailure('No file number returned.');
          }

          this._appApiSvc.clearCurrentModal();
          return new actions.CreateMatterSuccess(result);
        }),
        catchError(() => of(new actions.CreateMatterFailure('Could not create matter.')))
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private _matterListSvc: MatterListService,
    private _matterListStorageSvc: MatterListStorageService,
    private log: LogService,
    private platformService: PlatformService,
    private _eventBus: EventBusService,
    private _store: Store<State>,
    private _appApiSvc: AppApiService
  ) {}

  private handleInitListMatterError(): Observable<any> {
    this._store.dispatch(new actions.InitListMattersFailure(null));
    return throwError(
      new SiriusError({
        type: 'error',
        title: 'Failure',
        message: 'unable to initiate matter list',
      })
    );
  }
}
