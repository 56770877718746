import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AllMatterTypeId, IMatterTypePrecedents, IPrecedent, IPrecedentDraftValue, IPrecedentListUIInfo } from '../../models';
import * as actions from '../actions/precedent-list';
import { PrecedentContentSearchResult, ISchemaApiResponse } from '@app/shared/models';

export interface State extends EntityState<IMatterTypePrecedents> {
  loading: boolean;
  loadingFolderId: string;
  error: any | string | null;
  searchText: string | null;
  selectedPrecedent: IPrecedent;
  contentsearchResult: ISchemaApiResponse<PrecedentContentSearchResult[]>;
  totalPages: number;
  page: number;
  draft: IPrecedentDraftValue | null;
  filterByMatterType: string | null;
}

export const adapter: EntityAdapter<IMatterTypePrecedents> = createEntityAdapter<IMatterTypePrecedents>({
  selectId: (matterTypePrecedents: IMatterTypePrecedents) => matterTypePrecedents.matterTypeId,
});

export const initialState: State = adapter.getInitialState({
  loading: true,
  loadingFolderId: '',
  error: null,
  searchText: '',
  selectedPrecedent: null,
  contentsearchResult: undefined,
  totalPages: 0,
  page: 1,
  draft: null,
  filterByMatterType: null
});

export const reducer = (
  state = initialState,
  action: actions.PrecedentListApiActions | actions.PrecedentListActions
): State => {
  switch (action.type) {
    case actions.LIST_NESTED_PRECEDENTS_START: {
      return {
        ...state,
        loadingFolderId: action.payload.precedentId,
      };
    }

    case actions.SEARCH_PRECEDENT_BY_ALL:
      return {
        ...state,
        searchText: action.payload.searchText || '',
        filterByMatterType: AllMatterTypeId,
        loading: true,
      };

    case actions.SEARCH_PRECEDENT_BY_MATTER_TYPE:
      return {
        ...state,
        searchText: action.payload.searchText || '',
        filterByMatterType: null,
        loading: true,
      };

    case actions.SEARCH_PRECEDENT_SUCCESS_V2:
      return {
        ...adapter.upsertMany([action.payload.data] as IMatterTypePrecedents[], state),
        loading: false,
        page: 1,
        totalPages: 0,
      };

    case actions.SEARCH_PRECEDENT_FAIL_V2:
      return {
        ...state,
        loading: false,
      };

    case actions.SEARCH_PRECEDENT_BY_ALL_SUCCESS:
      return {
        ...adapter.upsertMany([action.payload.data] as IMatterTypePrecedents[], state),
        totalPages: action.payload.totalPages,
        page: action.payload.page,
        loading: false,
      };

    case actions.LIST_PRECEDENTS_SUCCESS: {
      return {
        ...adapter.upsertMany([action.payload] as IMatterTypePrecedents[], state),
        loading: false,
        loadingFolderId: '',
      };
    }

    case actions.CREATE_NEW_FOLDER_SUCCESS: {
      return {
        ...adapter.upsertMany([action.payload] as IMatterTypePrecedents[], state),
        draft: null
      };
    }

    case actions.PRECEDENT_TRANSACTION_FAILURE:
    case actions.CREATE_NEW_FOLDER_FAILURE:
    case actions.LIST_PRECEDENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingFolderId: '',
        error: action.payload,
        draft: null
      };
    }

    case actions.OPEN_PRECEDENT_SUCCESS:
    case actions.OPEN_PRECEDENT_FAILURE: {
      return {
        ...state,
        selectedPrecedent: null,
      };
    }

    case actions.RESET_PRECEDENT_LIST: {
      return initialState;
    }

    case actions.UPDATE_SELECTED_PRECEDENT_FOLDER:
    case actions.SELECT_PRECEDENT: {
      return {
        ...state,
        selectedPrecedent: action.payload.item,
      };
    }

    case actions.PRECEDENT_TRANSACTION_SUCCESS: {
      return {
        ...adapter.upsertOne(action.payload, state),
        loading: false,
      };
    }

    case actions.CREATE_READONLY_PRECEDENT_START:
    case actions.DUPLICATE_PRECEDENT_START:
    case actions.DELETE_PRECEDENT_START:
    case actions.UNDELETE_PRECEDENT_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.NEW_LOCAL_PRECEDENT_DRAFT_READY:
      return {
        ...state,
        draft: action.payload
      };

    case actions.CLEAR_LOCAL_PRECEDENT_DRAFT:
      return {
        ...state,
        draft: null
      };

    default: {
      return state;
    }
  }
};

export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;
export const selectLoadingFolderId = (state: State) => state.loadingFolderId;
export const selectSelectedPrecedentId = (state: State) =>
  !!state.selectedPrecedent ? state.selectedPrecedent.id : '';
export const selectSelectedPrecedent = (state: State) => state.selectedPrecedent;
export const selectPrecedentsTree = (state: State, matterTypeId: string) => {
  const selectedMatterTypePrecedents = state.entities[matterTypeId];
  return selectedMatterTypePrecedents ? selectedMatterTypePrecedents.precedents : [];
};
export const selectPage = (state: State) => state.page;
export const selectTotalPage = (state: State) => state.totalPages;
export const selectSearchText = (state: State) => state.searchText;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the dictionary of user entities
export const selectMatterTypePrecedentsEntities = selectEntities;

export const selectNewPrecedentDraft = (state: State) => state.draft;

export const selectFilterByMatterType = (state: State) => state.filterByMatterType;
