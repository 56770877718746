import { Injectable } from '@angular/core';
import { BaseStorageService } from '@app/shared/services/base/base-storage.service';
import { IPersonListEntry, IPersonListMetaInfo, IPersonListResponseSchema } from '@app/features/+person/models';
import { DexieService } from '@app/core/services/dexie/dexie.service';
import { PlatformService } from '@app/core/services/platform/platform.service';
import { LogService } from '@app/core/services/log/log.service';
import Dexie from 'dexie';
import { from, Observable, throwError } from 'rxjs';

@Injectable()
export class PersonListStorageService extends BaseStorageService<IPersonListEntry> {
  private metaKey = '__PERSON_LIST_META__';
  private metaTable: Dexie.Table<IPersonListMetaInfo, string>;

  constructor(private dexieSvc: DexieService, private log: LogService, private _platformSvc: PlatformService) {
    super();
    log.init('person-list-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this.dexieSvc.table('persons');
      this.metaTable = this.dexieSvc.table('personsMeta');
    }
  }

  public getMeta(): Observable<IPersonListMetaInfo> {
    if (this._platformSvc.isBrowser) {
      return from(this.metaTable.get(this.metaKey));
    }
  }

  public upsertAll(res: IPersonListResponseSchema) {
    if (this._platformSvc.isBrowser) {
      const metaInfo: Partial<IPersonListMetaInfo> = {
        id: this.metaKey,
        lastRowVer: res.lastRowVer,
      };

      this.log.info(metaInfo);

      return this.upsertMeta(metaInfo).then(() => this.table.bulkPut(res.data));
    }
  }

  public upsertMeta(partialMetaInfo: Partial<IPersonListMetaInfo>) {
    if (this._platformSvc.isBrowser) {
      return this.metaTable.get(this.metaKey).then((currentMeta: IPersonListMetaInfo) => {
        const metaInfo = {
          ...currentMeta,
          ...partialMetaInfo,
          id: this.metaKey,
        };
        return this.metaTable.put(metaInfo);
      });
    }
  }

  public handleDatabaseError(error: any): any {
    return throwError(error);
  }
}
