// core
import { Injectable, NgZone } from '@angular/core';

import { ExternalUserType } from '@app/shared/models';
import { PlatformService } from '../platform/platform.service';
import { environment } from '@env/environment';
import { AuthAgent } from '@leapdev/auth-agent';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _platformSvc: PlatformService, private _ngZone: NgZone) {
    console.log('auth-service ctor');
  }

  async userDetails() {
    if (this._platformSvc.isBrowser) {
      console.log('fetching user details from authagent');
      const userInfo = await AuthAgent.userInfo();
      return userInfo;
    }
  }

  async statusAdminConsent() {
    if (this._platformSvc.isBrowser) {
      const status = await AuthAgent.statusAdminConsent();
      return !!status;
    }
  }

  requestAdminConsent(redirectUrl: string, openInNewWindow: boolean, cb) {
    if (this._platformSvc.isBrowser) {
      return AuthAgent.getAdminConsent(undefined, redirectUrl, openInNewWindow, cb);
    }
  }

  forceRefreshAccessToken() {
    if (this._platformSvc.isBrowser) {
      return AuthAgent.getAccessToken();
    }
  }

  get decodedToken() {
    if (this._platformSvc.isBrowser) {
      return AuthAgent.getDecodedAccessToken();
    }
  }

  get token() {
    if (this._platformSvc.isBrowser) {
      return AuthAgent.getAccessToken();
    }
  }

  getRefreshedToken = async () => await AuthAgent.getRefreshedAccessToken();

  linkUser(url?: string, newWindow?: boolean, callback?) {
    if (this._platformSvc.isBrowser) {
      return AuthAgent.linkUser(url, newWindow, callback);
    }
  }

  unlinkUser(url?: string, newWindow?: boolean, callback?) {
    if (this._platformSvc.isBrowser) {
      return AuthAgent.unlinkUser(url, newWindow, callback);
    }
  }

  reauthenticateCloudProvider(
    nonce?: string,
    redirect?: string,
    newWindow?: boolean,
    callback?,
    isSuperdiaryFirm = false
  ) {
    if (this._platformSvc.isBrowser) {
      if (!isSuperdiaryFirm) {
        return AuthAgent.cloudProviderReauthenticate(nonce, redirect, newWindow, callback);
      } else {
        this.openAzureTenantEmailSetting();
      }
    }
  }

  registerEventListener(topic, type, callback) {
    console.log(AuthAgent);
    AuthAgent.registerEventListener(topic, type, callback);
  }

  authoriseSupport(code, duration) {
    AuthAgent.authoriseSupport(code, duration);
  }

  logout() {
    if (this._platformSvc.isBrowser) {
      AuthAgent.logout();
    }
  }

  getProviderText(userType: ExternalUserType): string {
    let text = 'Cloud Provider';
    if (!userType) {
      return text;
    }
    switch (userType) {
      case ExternalUserType.Azuer:
        text = 'Office 365';
        break;
      case ExternalUserType.Google:
        text = 'Google';
        break;
      case ExternalUserType.Exchange:
        text = 'Exchange';
        break;
    }
    return text;
  }

  redirectToOptionEmailAccountPage(isSuperdiaryFirm: boolean): void {
    // due to new setting yet fully supported, therefore, traffic need to reroute to legacy link approach.
    if (!isSuperdiaryFirm) {
      this.linkUser(null, true);
      return;
    }

    this.openAzureTenantEmailSetting();
  }

  openAzureTenantEmailSetting(): void {
    const optionEndpoint = environment.config.endpoint.options_app;
    const integrationsUrl = optionEndpoint && `${optionEndpoint}/integrations/email-accounts`;
    if (integrationsUrl && this._platformSvc.isBrowser) {
      this._ngZone.runOutsideAngular(() => AuthAgent.passthrough(integrationsUrl, true, environment.config.endpoint.auth));
    }
  }

  changePassword(params: { redirectUrl?: string; newWindow: boolean; callback?: any }) {
    if (this._platformSvc.isBrowser) {
      const { redirectUrl, newWindow, callback } = params;
      AuthAgent.changePassword(redirectUrl, newWindow, callback);
    }
  }
}

interface AuthAgentModel {
  cloudProviderReauthenticate: (nonce: string, redirectUrl: string, newWindow: boolean, callback) => void;
  cloudProviderUserInfo: () => Promise<any>;
  getAccessToken: (refresh?: boolean) => any;
  getAdminConsent: (domain: string, redirectUrl: string, newWindow: boolean, callback) => void;
  getCloudProviderToken: (jti) => Promise<any>;
  getDecodedAccessToken: () => any;
  getLinkMap: () => Promise<any>;
  linkUser: (redirectUrl: string, newWindow: boolean, callback) => void;
  logout: () => void;
  registerHook: () => void;
  revokeAdminConsent: () => Promise<any>;
  setLinkMap: (linkmap) => Promise<any>;
  statusAdminConsent: () => Promise<boolean>;
  unlinkUser: (redirectUrl: string, newWindow: boolean, callback) => void;
  userInfo: () => any;
  registerEventListener(topic: string, type: string, callback): () => void;
  authoriseSupport(code: string, duration): () => void;
  changePassword: (redirectUrl: string, newWindow: boolean, callback: any) => void;
}
