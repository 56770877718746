import { Action } from '@ngrx/store';

import { IMatterListEntry, IMatterSearchState } from '../../../models';
import { AdvancedSearchMode, EMattersFilter } from '@app/features/+matter-list/constants';

export interface ICloudSearchPayload {
  searchMode: AdvancedSearchMode;
  data: (IMatterListEntry | string)[];
}

export const SEARCH_MATTERS_START = '[Matter search] start';
export const SEARCH_MATTERS_SUCCESS = '[Matter search] success';

export const SEARCH_MATTERS_CLOUD_START = '[Matter search on cloud] start';
export const SEARCH_MATTERS_CLOUD_SUCCESS = '[Matter search on cloud] success';
export const SEARCH_MATTERS_CLOUD_FAILURE = '[Matter search on cloud] failure';

export class SearchMattersStart implements Action {
  readonly type = SEARCH_MATTERS_START;
  constructor(public payload: Partial<IMatterSearchState>) {}
}

export class SearchMattersSuccess implements Action {
  readonly type = SEARCH_MATTERS_SUCCESS;
  constructor(public payload: any) {}
}

export class SearchMattersCloudStart implements Action {
  readonly type = SEARCH_MATTERS_CLOUD_START;
  constructor(public payload: EMattersFilter) {}
}

export class SearchMattersCloudSuccess implements Action {
  readonly type = SEARCH_MATTERS_CLOUD_SUCCESS;
  constructor(public payload: ICloudSearchPayload) {}
}

export class SearchMattersCloudFailure implements Action {
  readonly type = SEARCH_MATTERS_CLOUD_FAILURE;
  constructor(public payload: any) {
    console.log(payload);
  }
}

export type MatterSearchApiActions =
  | SearchMattersStart
  | SearchMattersSuccess
  | SearchMattersCloudStart
  | SearchMattersCloudSuccess
  | SearchMattersCloudFailure;
