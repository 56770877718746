import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-text-primary-cell',
  templateUrl: './text-primary-cell.component.html',
})
export class TextPrimaryCellComponent implements ICellRendererAngularComp {
  text: string;
  // description: string;
  textClass = 'text-primary-lighter';
  params: any;

  agInit(params: any): void {
    const rowObj = params.data;
    this.params = params;
    this.text = isFunction(params['getName']) ? params.getName(rowObj) : '';
    this.textClass = isFunction(params['getTextClass']) ? params.getTextClass(rowObj) : 'text-primary-lighter';

    // this.description = params.getDescription(rowObj) ? params.getDescription(rowObj) : '';
  }

  refresh(params: any): boolean {
    const rowObj = params.data;
    this.text = isFunction(params['getName'])
      ? params.getName(rowObj)
      : isFunction(this.params['getName'])
      ? this.params.getName(rowObj)
      : '';
    this.textClass = isFunction(params['getTextClass']) ? params.getTextClass(rowObj) : 'text-primary-lighter';
    return true;
  }
}
