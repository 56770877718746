import * as sidebarActions from '@app/core/store/actions/sidebar.action';
import { ISortInfo, IDocsFolder, Correspondence, FolderValidation } from '@app/shared/models';
import * as actions from '../actions';
import { RootFolderId } from '../../constants';
import { ICorrespondenceListUIInfo, CorrespondenceFilter } from '../../models';
import { buildFolderPath } from '../../functions';

export interface State {
  matterId: string;
  root: IDocsFolder;
  selectedFolderId: string;
  selectedFolder: IDocsFolder;
  selectedCorrespondence: Correspondence; // either folder or document correspondence
  selectedCorrespondenceId: string;
  searchText: string;
  sortInfo: ISortInfo;
  showDeleted: boolean;
  folderPath: IDocsFolder[];
  loading: boolean;
  updatingFromApi: boolean;
  errorCounter: number;
  dbSaveStatus: string;
  uiInfo: ICorrespondenceListUIInfo;
  folderActionRef: boolean;
  extensionFilter: CorrespondenceFilter;

  moving: boolean;
  moveStatus: null | 'success' | string;
}

export const initialState: State = {
  matterId: '',
  root: undefined,
  selectedFolderId: RootFolderId,
  selectedFolder: undefined,
  selectedCorrespondence: undefined,
  selectedCorrespondenceId: '',
  searchText: '',
  sortInfo: undefined,
  showDeleted: false,
  folderPath: [],
  loading: false,
  updatingFromApi: false,
  errorCounter: 0,
  dbSaveStatus: '',
  uiInfo: {
    showDeleted: false,
    showCreated: true,
  },
  folderActionRef: undefined,
  extensionFilter: {
    appliedFilterOptions: [],
    availableFilterOptions: [],
  },
  moving: false,
  moveStatus: null,
};

export const reducer = (
  state = initialState,
  action: actions.FolderApiActions | actions.FolderDbActions | sidebarActions.SidebarActions
): State => {

  switch (action.type) {
    case sidebarActions.SIDEBAR_NEW_FOLDER: {
      return {
        ...state,
        folderActionRef: !state.folderActionRef,
      };
    }

    case actions.LOAD_CORRESPONDENCE_LIST_DB_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.LOAD_CORRESPONDENCE_LIST_DB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case actions.LOAD_CORRESPONDENCE_LIST_DB_FAILURE: {
      return {
        ...state,
        loading: false,
        errorCounter: state.errorCounter + 1,
      };
    }

    case actions.CORRESPONDENCE_LIST_SAVE_DB_START: {
      const updatedState = {
        ...state,
        ...action.payload,
        dbSaveStatus: 'start',
      };
      const { selectedFolder, root } = updatedState;
      return {
        ...updatedState,
        folderPath: buildFolderPath(selectedFolder, root),
      };
    }

    case actions.CORRESPONDENCE_LIST_SAVE_DB_SUCCESS:
    case actions.CORRESPONDENCE_LIST_SAVE_DB_FAILURE: {
      return {
        ...state,
        dbSaveStatus: action.payload,
      };
    }

    case actions.LIST_CORRESPONDENCE_LIST_START: {
      return {
        ...state,
        updatingFromApi: true,
      };
    }

    case actions.LIST_CORRESPONDENCE_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        updatingFromApi: false,
      };
    }

    case actions.SELECT_CORRESPONDENCE: {
      return {
        ...state,
        selectedCorrespondence: action.payload.item,
        selectedCorrespondenceId: action.payload.item.id,
      };
    }

    case actions.SELECT_CORRESPONDENCE_UPDATE: {
      return {
        ...state,
        selectedCorrespondence: action.payload.item,
        selectedCorrespondenceId: action.payload.item.id,
      };
    }

    case actions.RENAME_FOLDER_FAILURE:
    case actions.CREATE_FOLDER_FAILURE:
    case actions.LIST_CORRESPONDENCE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        updatingFromApi: false,
        errorCounter: state.errorCounter + 1,
      };
    }

    case actions.UNLOAD_CORRESPONDENCE_LIST: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.CLEAR_CORRESPONDENCE_LIST: {
      return initialState;
    }

    case actions.MOVE_TO_FOLDER_START: {
      return {
        ...state,
        moving: true,
        moveStatus: null,
      };
    }

    case actions.MOVE_TO_FOLDER_SUCCESS: {
      return {
        ...state,
        moving: false,
        moveStatus: 'success',
      };
    }

    case actions.MOVE_TO_FOLDER_FAILURE: {
      return {
        ...state,
        moving: false,
        moveStatus: action.payload,
      };
    }

    case actions.ADD_AVAILABLE_FILTER_OPTIONS: {
      const updatedFilter = {
        appliedFilterOptions: state.extensionFilter.appliedFilterOptions,
        availableFilterOptions: [...action.payload],
      } as CorrespondenceFilter;
      return {
        ...state,
        extensionFilter: updatedFilter,
      };
    }

    case actions.UPDATE_APPLIED_FILTER_OPTIONS: {
      const index = state.extensionFilter.appliedFilterOptions.indexOf(action.payload);
      let updatedAppliedFilters = [];

      if (index === -1) {
        updatedAppliedFilters = [...state.extensionFilter.appliedFilterOptions, action.payload];
      } else {
        updatedAppliedFilters = [
          ...state.extensionFilter.appliedFilterOptions.slice(0, index),
          ...state.extensionFilter.appliedFilterOptions.slice(index + 1),
        ];
      }

      const updatedFilter = {
        appliedFilterOptions: updatedAppliedFilters,
        availableFilterOptions: state.extensionFilter.availableFilterOptions,
      } as CorrespondenceFilter;
      return {
        ...state,
        extensionFilter: updatedFilter,
      };
    }

    case actions.RESET_FILTER: {
      const updatedFilter = {
        appliedFilterOptions: [],
        availableFilterOptions: state.extensionFilter.availableFilterOptions,
      } as CorrespondenceFilter;
      return {
        ...state,
        extensionFilter: updatedFilter,
      };
    }

    case actions.RENAME_FOLDER_START:
    case actions.CREATE_FOLDER_START:
    case actions.DELETE_FOLDER_START:
    default: {
      return state;
    }
  }
};

export const selectRoot = (state: State) => state.root;
export const selectSelectedFolder = (state: State) => state.selectedFolder;
export const selectSelectedFolderId = (state: State) => state.selectedFolderId;
export const selectSelectedCorrespondence = (state: State) => state.selectedCorrespondence;
export const selectSelectedCorrespondenceId = (state: State) => state.selectedCorrespondenceId;
export const selectErrorCounter = (state: State) => state.errorCounter;
export const selectLoading = (state: State) => state.loading;
export const selectUpdatingFromApi = (state: State) => state.updatingFromApi;
export const selectFolderPath = (state: State) => state.folderPath;
export const selectSearchText = (state: State) => state.searchText;
export const selectMatterId = (state: State) => state.matterId;
export const selectSortInfo = (state: State) => state.sortInfo;
export const selectUIInfo = (state: State) => state.uiInfo;
export const selectFolderActionRef = (state: State) => state.folderActionRef;

export const selectMoving = (state: State) => state.moving;
export const selectMoveStatus = (state: State) => state.moveStatus;
export const selectAppliedFilter = (state: State) => state.extensionFilter.appliedFilterOptions;
export const selectAvailableFilter = (state: State) => state.extensionFilter.availableFilterOptions;
