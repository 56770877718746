import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AccountingService } from '@app/features/accounting/services';
import { AggridEditorAbstract } from '@app/sharedaggrid/components/aggrid-editor/aggrid-editor.abstract';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-amount-editor',
  templateUrl: './amount-editor.component.html',
})
export class AmountEditorComponent extends AggridEditorAbstract implements ICellEditorAngularComp {
  private node: any;
  private balanceDue: number;
  private amountRestricted: boolean;
  private onAmountBlur: (params: any, value: number) => void;
  private onAmountChange: (params: any, value: number) => void;
  public icon: 'dollar' | 'percentage' = 'dollar';

  saveOnTab = false;

  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;

  constructor(private accountingService: AccountingService) {
    super();
  }

  agInit(params: any): void {
    super.agInit(params);
    this.node = params.node.data;
    this.saveOnTab = params.saveOnTab;
    this.value = (isFunction(params.getAmount) ? params.getAmount(this.node) : params.value) || 0;
    this.onAmountBlur = isFunction(params.onAmountBlur) ? params.onAmountBlur : null;
    this.onAmountChange = isFunction(params.onAmountChange) ? params.onAmountChange : null;

    // TODO: remove check BalanceDue property condition to make component generic
    this.balanceDue = (isFunction(params.getBalanceDue) ? params.getBalanceDue(this.node) : this.node.BalanceDue) || 0;
    // TODO: remove check InvoiceGUID property condition to make component generic
    this.amountRestricted =
      (isFunction(params.getAmountRestricted) ? params.getAmountRestricted(this.node) : !!this.node.InvoiceGUID) ||
      false;
    this.value = this.calculateAmount();
    if (!!params.icon) {
      this.icon = params.icon;
    }
  }

  private calculateAmount(): number {
    const defaultAmount = !!this.getValue() ? this.getValue() : 0;
    // if this item has been fully resolved, don't mess it up again
    // or there is no available balance to add
    if (this.balanceDue === 0 || this.params.OutOfBalance < 0) {
      return defaultAmount;
    }

    // attempt to set amount to maximum possible value if amount is not set yet
    if (defaultAmount === 0) {
      if (this.balanceDue > Math.abs(this.params.OutOfBalance)) {
        // this outstanding item is too large, more than what we have, so just set it to whatever we have
        return Math.abs(this.params.OutOfBalance);
      } else {
        // we have a lot of free cash, so fully settle this item
        return this.balanceDue;
      }
    } else {
      return defaultAmount;
    }
  }

  getValue(): any {
    return this.accountingService.round(this.value);
  }

  focusIn() {
    this.input.element.nativeElement.focus();
    this.input.element.nativeElement.select();
  }

  blur(): void {
    if (this.onAmountBlur) {
      this.onAmountBlur(this.params, this.value);
    }
  }

  stopEditing(cancel?: boolean): void {
    this.params.api.stopEditing(cancel);
  }

  handleValueChange(newValue: number) {
    if (this.amountRestricted) {
      // office receipt
      if (this.balanceDue >= newValue) {
        this.value = newValue;
      } else {
        this.value = this.balanceDue;
      }
    } else {
      // credit receipt, amount unrestricted
      this.value = newValue;
    }

    if (this.onAmountChange) {
      this.onAmountChange(this.params, this.value);
    }
  }
}
