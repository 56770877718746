import { Action } from '@ngrx/store';

import { IMatterSearchState } from '../../../models';

export const SEARCH_SAVE_DB_START = '[Search save to db] start';
export const SEARCH_SAVE_DB_SUCCESS = '[Search save to db] success';
export const SEARCH_SAVE_DB_FAILURE = '[Search save to db] failure';

export const LOAD_MATTERS_SEARCH_START = '[Load matter search] start';
export const LOAD_MATTERS_SEARCH_SUCCESS = '[Load matter search] success';

export class SearchSaveDbStart implements Action {
  readonly type = SEARCH_SAVE_DB_START;
  constructor(public payload: IMatterSearchState) {}
}

export class SearchSaveDbSuccess implements Action {
  readonly type = SEARCH_SAVE_DB_SUCCESS;
  constructor(public payload: string) {}
}

export class SearchSaveDbFailure implements Action {
  readonly type = SEARCH_SAVE_DB_FAILURE;
  constructor(public payload: string) {}
}

export class LoadMattersSearchStart implements Action {
  readonly type = LOAD_MATTERS_SEARCH_START;
  constructor(public payload: any) {}
}

export class LoadMattersSearchSuccess implements Action {
  readonly type = LOAD_MATTERS_SEARCH_SUCCESS;
  constructor(public payload: IMatterSearchState) {}
}

export type MatterSearchDbActions =
  | SearchSaveDbStart
  | SearchSaveDbSuccess
  | SearchSaveDbFailure
  | LoadMattersSearchStart
  | LoadMattersSearchSuccess;
