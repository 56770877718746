import { Action } from '@ngrx/store';
import { IMatterDetailEntry } from '@app/shared/models';

import {
  CreateMatterSuccessPayload,
  IMatterListEntry,
  IMatterListResponseSchema,
  INewMatterParams,
} from '../../../models';
import { ICloudSearchPayload } from '../matter-search/matter-search-api.action';

export const INIT_LIST_MATTERS_START = '[Matter List from api] init start';
export const INIT_LIST_MATTERS_SUCCESS = '[Matter List from api] init success';
export const INIT_LIST_MATTERS_FAILURE = '[Matter List from api] init failure';

export const LIST_MATTERS_START = '[Matter List from api] update matter list start';
export const LIST_MATTERS_SUCCESS = '[Matter List from api] update matter list success';
export const LIST_MATTERS_FAILURE = '[Matter List from api] update matter list failure';

export const CLOUD_SEARCH_START = '[Matters List] cloud search start';
export const CLOUD_SEARCH_SUCCESS = '[Matters List] cloud search success';

export const DELETE_MATTER_START = '[Delete matter] start';
export const DELETE_MATTER_SUCCESS = '[Delete matter] success';
export const DELETE_MATTER_FAILURE = '[Delete matter] failure';

export const CREATE_MATTER_START = '[Create matter] start';
export const CREATE_MATTER_SUCCESS = '[Create matter] success';
export const CREATE_MATTER_FAILURE = '[Create matter] failure';
export const CREATE_MATTER_WARNING = '[Create matter] warning';
export const CREATE_MATTER_LOADING_STATUS = '[Create matter] loading status';
export const REUPDATED_MATTER_DETAIL_ENTRIES = '[Reupdate matter] reupdate the matter detail';

export class InitListMattersStart implements Action {
  readonly type = INIT_LIST_MATTERS_START;

  constructor(public payload: any) {}
}

export class InitListMattersSuccess implements Action {
  readonly type = INIT_LIST_MATTERS_SUCCESS;

  constructor(public payload: IMatterListResponseSchema) {}
}

export class InitListMattersFailure implements Action {
  readonly type = INIT_LIST_MATTERS_FAILURE;

  constructor(public payload: any) {}
}

export class ListMattersStart implements Action {
  readonly type = LIST_MATTERS_START;
  constructor(public payload: number) {}
}

export class ListMattersSuccess implements Action {
  readonly type = LIST_MATTERS_SUCCESS;
  constructor(public payload: IMatterListResponseSchema) {}
}

export class ListMattersFailure implements Action {
  readonly type = LIST_MATTERS_FAILURE;
  constructor(public payload: any) {}
}

export class CloudSearchStart implements Action {
  readonly type = CLOUD_SEARCH_START;
  constructor(public payload: any) {}
}

export class CloudSearchSuccess implements Action {
  readonly type = CLOUD_SEARCH_SUCCESS;
  constructor(public payload: ICloudSearchPayload) {}
}

export class DeleteMatterStart implements Action {
  readonly type = DELETE_MATTER_START;
  constructor(public payload: IMatterListEntry) {}
}

export class DeleteMatterSuccess implements Action {
  readonly type = DELETE_MATTER_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteMatterFailure implements Action {
  readonly type = DELETE_MATTER_FAILURE;
  constructor(public payload: any) {}
}

export class CreateMatterStart implements Action {
  readonly type = CREATE_MATTER_START;
  constructor(public payload: INewMatterParams) {}
}

export class CreateMatterSuccess implements Action {
  readonly type = CREATE_MATTER_SUCCESS;
  constructor(public payload: CreateMatterSuccessPayload) {}
}

export class CreateMatterFailure implements Action {
  readonly type = CREATE_MATTER_FAILURE;
  constructor(public payload: string) {}
}

export class CreateMatterWarning implements Action {
  readonly type = CREATE_MATTER_WARNING;
  constructor(public payload: CreateMatterSuccessPayload) {}
}

export class CreateMatterLoadingStatus implements Action {
  readonly type = CREATE_MATTER_LOADING_STATUS;
  constructor(public payload: boolean) {}
}

export class ReupdatedMatterDetailEntries implements Action {
  readonly type = REUPDATED_MATTER_DETAIL_ENTRIES;
  constructor(
    public payload: { matterEntries: IMatterDetailEntry[]; matterTypeId: string; fileNumber: string; matterId: string }
  ) {}
}

export type MatterListApiActions =
  | InitListMattersStart
  | InitListMattersSuccess
  | InitListMattersFailure
  | ListMattersStart
  | ListMattersSuccess
  | ListMattersFailure
  | CloudSearchStart
  | CloudSearchSuccess
  | DeleteMatterStart
  | DeleteMatterSuccess
  | DeleteMatterFailure
  | CreateMatterStart
  | CreateMatterSuccess
  | CreateMatterFailure
  | CreateMatterWarning
  | CreateMatterLoadingStatus
  | ReupdatedMatterDetailEntries;
