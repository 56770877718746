import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as cardListActions from '../actions/card-list';
import * as cardDetailsActions from '@app/features/+card/store/actions/card-details';
import * as personListActions from '@app/features/+person/store/actions/person-list';
import {
  selectCardDetailsLoading,
  selectCardListInitiated,
  selectIsCardDetailsModified,
  selectSelectedCardId,
} from '@app/features/+card/store/selectors';
import { CardListStorageService } from '@app/features/+card/services';
import { CardListService } from '@app/features/+card/services/card-list.service';
import { ICardListEntry, ICardListResponseSchema } from '@app/features/+card/models';
import { TranslateService } from '@ngx-translate/core';
import { EventBusService, PlatformService } from '@app/core/services';
import * as namedListActions from '@app/features/+card/store/actions/named-list';
import { INITIAL_STATE } from '@app/features/+card/store/reducers';
import * as tableMappingActions from '@app/core/store/actions';
import { ESiriusEvents } from '@app/core/models';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { hasProp } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class CardListEffects {

  initListCardsStart$ = createEffect(() => this.actions$.pipe(
    ofType<cardListActions.InitListCardsStart>(cardListActions.CardListApiActionTypes.INIT_LIST_CARDS_START),
    withLatestFrom(this.store.pipe(select(selectCardListInitiated)), (action, initiated) => initiated),
    filter((initiated) => !!this.platformService.isBrowser && !initiated),
    switchMap(() =>
      this.cardListStorageSvc.getMeta().pipe(map((meta) => (!!meta && !!meta.lastRowVer ? meta.lastRowVer : 0)))
    ),
    tap(() => this.store.dispatch(new tableMappingActions.AddTableMapping(null))),
    switchMap((lastRowVer) =>
      this.cardListSvc.getAll(lastRowVer).pipe(
        catchError((err) => {
          this.store.dispatch(new cardListActions.InitListCardsFailure(null));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to initiate card list.',
            })
          );
        })
      )
    ),
    mergeMap((listResponse) => [
      new namedListActions.InitNamedListsStart(null),
      new personListActions.ListPersonsStart(null),
      new cardListActions.InitListCardsSuccess(null),
      new cardListActions.CardSaveDbStart(listResponse),
    ])
  ));


  listCards$ = createEffect(() => this.actions$.pipe(
    ofType<cardListActions.ListCardsStart>(cardListActions.CardListApiActionTypes.LIST_CARDS_START),
    filter(() => this.platformService.isBrowser),
    switchMap(() =>
      this.cardListStorageSvc.getMeta().pipe(map((meta) => (!!meta && !!meta.lastRowVer ? meta.lastRowVer : 0)))
    ),
    switchMap((lastRowVer) => this.cardListSvc.getAll(lastRowVer).pipe(
        mergeMap((res: ICardListResponseSchema) => {
          res.data = res.data.map((resCard) => {
            if (!hasProp(resCard, 'deleteCode')) {
              resCard.deleteCode = 0; // force the cardListEntry to have a deleteCode property
            }
            return resCard;
          });
          return [new cardListActions.ListCardsSuccess(res), new cardListActions.CardSaveDbStart(res)];
        }),
        catchError((error) => {
          this.store.dispatch(new cardListActions.ListCardsFailure(error));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to load card list.',
            })
          );
        })
      ))
  ));


  saveCardToDb$ = createEffect(() => this.actions$.pipe(
    ofType(cardListActions.CardListDbActionTypes.CARDS_SAVE_DB_START),
    filter(() => this.platformService.isBrowser),
    switchMap((action: cardListActions.CardSaveDbStart) => this.cardListStorageSvc
        .upsertAll(action.payload)
        .catch((error) => new cardListActions.CardSaveDbFailure(error))),
    switchMap(() => this.cardListStorageSvc.getAll().pipe(
        mergeMap((data) => {
          this.cardListStorageSvc.refreshAll(data);
          return [new cardListActions.CardSaveDbSuccess('success')];
        })
      )),
    catchError((error) => of(new cardListActions.CardSaveDbFailure(error)))
  ));


  loadCardListMetaFromDb$: any = createEffect(() => this.actions$.pipe(
    ofType(cardListActions.CardListDbActionTypes.LOAD_CARD_LIST_META_START),
    filter(() => this.platformService.isBrowser),
    switchMap(() => this.cardListStorageSvc.getMeta().pipe(
        mergeMap((data) => {
          let actions: any[] = [new cardListActions.LoadCardListMetaSuccess(data)];
          if (!!data && !!data.selectedCardId) {
            actions = [...actions, new cardDetailsActions.LoadCardDetailsStart({ id: data.selectedCardId })];
          }
          return actions;
        })
      )),
    catchError((error) => {
      const { sortInfo, cardSearchText, cardGroupBy, cardFilterBy, showDeleted } = INITIAL_STATE.cardList;
      return of(
        new cardListActions.CardListMetaSaveDbStart({
          sortInfo,
          cardSearchText,
          cardGroupBy,
          cardFilterBy,
          showDeleted,
        })
      );
    })
  ));


  saveCardListMetaToDb$: any = createEffect(() => this.actions$.pipe(
    ofType(cardListActions.CardListDbActionTypes.CARD_LIST_META_SAVE_DB_START),
    filter(() => this.platformService.isBrowser),
    switchMap((action: cardListActions.CardListMetaSaveDbStart) => this.cardListStorageSvc
        .upertMeta(action.payload)
        .then(() => new cardListActions.CardListMetaSaveDbSuccess('success'))
        .catch((error) => new cardListActions.CardListMetaSaveDbFailure(error))),
    catchError((error) => of(new cardListActions.CardListMetaSaveDbFailure(error)))
  ));


  selectCard$: any = createEffect(() => this.actions$.pipe(
    ofType<cardListActions.SelectCard>(cardListActions.CardListApiActionTypes.SELECT_CARD),
    withLatestFrom(
      this.store.pipe(select(selectIsCardDetailsModified)),
      this.store.pipe(select(selectSelectedCardId)),
      this.store.pipe(select(selectCardDetailsLoading)),
      (action, isCardDetailsModified, selectedCardId, isCardDetailsLoading) => ({
        targetCard: action.payload.card,
        isCardDetailsModified,
        selectedCardId,
        isCardDetailsLoading,
      })
    ),
    filter((data) => !data.isCardDetailsLoading && !!data.targetCard),
    tap((data) => {
      const { targetCard, isCardDetailsModified, selectedCardId } = data;
      if (isCardDetailsModified && selectedCardId !== targetCard.cardId) {
        this._eventBus.emit({
          name: ESiriusEvents.ShowDialog,
          value: {
            type: 'confirm',
            title: this.translateSvc.instant('Card.New.Discard.Title'),
            message: this.translateSvc.instant('Card.New.Discard.Message'),
            showCancel: true,
            actionText: this.translateSvc.instant('Card.New.Discard.Action.Text'),
            closeText: this.translateSvc.instant('Card.New.Discard.Close.Text'),
            onClose: (leaveConfirmed) => {
              if (leaveConfirmed) {
                this.confirmCardSelection(targetCard);
              }
            },
          },
        });
      } else {
        this.confirmCardSelection(targetCard);
      }
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private translateSvc: TranslateService,
    private cardListSvc: CardListService,
    private platformService: PlatformService,
    private _eventBus: EventBusService,
    private cardListStorageSvc: CardListStorageService
  ) {}

  private confirmCardSelection(card: ICardListEntry) {
    this.store.dispatch(new cardListActions.CardListMetaSaveDbStart({ selectedCardId: card.cardId }));
    this.store.dispatch(new cardDetailsActions.LoadCardDetailsStart({ id: card.cardId }));
  }
}
