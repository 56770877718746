import { BehaviorSubject, from as observableFrom, Observable, throwError } from 'rxjs';
import { Dexie } from 'dexie';
import { catchError } from 'rxjs/operators';

export abstract class BaseStorageService<T> {
  table: Dexie.Table<T, string>;

  private getAllSubject = new BehaviorSubject<T[]>(undefined);

  constructor() { }

  abstract handleDatabaseError(error: any);

  // emit the latest table array
  refreshAll(data): void {
    this.getAllSubject.next(data);
  }

  // a stream of the table array
  getAllStream(): Observable<T[]> {
    return this.getAllSubject.asObservable();
  }

  getAll(orderBy?: string): Observable<T[]> {
    if (!!this.table) {
      const all = !!orderBy ? this.table.orderBy(orderBy).toArray() : this.table.toArray();

      // TODO: Relaying on the physical order could be dangerous!
      /*
      if (this.table.core.name === "notifications") {
        console.log('^^^^^^^^^^^^^ getAll: table, all, orderBy:', this.table, all, orderBy);
      }
      */
      return observableFrom(all).pipe(
        catchError((err) => {
          this.handleDatabaseError(err);
          return throwError(err);
        })
      );
    } else {
      return observableFrom([]);
    }
  }

  getByIds(ids: string[]): Promise<T[]> {
    return !!this.table && !!ids && ids.length > 0
      ? this.table.bulkGet(ids).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve([]);
  }

  get(id: string): Promise<T | undefined> {
    return !!this.table && !!id
      ? this.table.get(id).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }

  add(data: T): Promise<string> {
    return !!this.table
      ? this.table.add(data).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }

  bulkAdd(data: T[]): Promise<string> {
    return !!this.table
      ? this.table.bulkAdd(data).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }

  bulkPut(data: T[]): Promise<string> {
    return !!this.table
      ? this.table.bulkPut(data).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }

  bulkDelete(ids: any[]): Promise<void> {
    return !!this.table
      ? this.table.bulkDelete(ids).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }

  put(data: T): Promise<string> {
    return !!this.table
      ? this.table.put(data).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }

  clear(): Promise<void> {
    return !!this.table
      ? this.table.clear().catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }

  update(id: string, data: T): Promise<number> {
    return !!this.table
      ? this.table.update(id, data).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }

  remove(id: string): Promise<void> {
    return !!this.table
      ? this.table.delete(id).catch((error) => {
        this.handleDatabaseError(error);
        throw error;
      })
      : Promise.resolve(undefined);
  }
}
