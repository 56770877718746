import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { RootFolderId } from '../constants';
import { FolderValidation, IDoc, IDocsFolder } from '@app/shared/models';
import { BaseService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class FolderService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  addDocuments(matterId: string, documents: IDoc[], toFolder: IDocsFolder): Observable<void> {
    const url = this.urlJoin(this.apiPath, `/api/v1/matters/${matterId}/folders`, toFolder.id);
    const message = {
      folderId: toFolder.id,
      docs: documents?.map((x) => x.id) || [],
    };
    return this.http.post<void>(url, message);
  }

  create(matterId: string, folder: IDocsFolder): Observable<void | FolderValidation> {
    const { parent, id, name } = folder;
    const folderStatus = this.validateFolder(matterId, name, parent);
    if (folderStatus !== FolderValidation.NoError) {
      return observableThrowError(folderStatus);
    }

    const url = this.urlJoin(this.apiPath, `/api/v1/matters/${matterId}/folders`);
    const message = [
      {
        parentId: originalId(parent),
        folderId: id,
        folderName: name,
      },
    ];
    return this.http.post<void>(url, message);
  }

  delete(matterId: string, folder: IDocsFolder): Observable<void> {
    const url = this.urlJoin(this.apiPath, `/api/v1/matters/${matterId}/folders`, originalId(folder));
    return this.http.delete<void>(url);
  }

  moveDocuments(matterId: string, documents: IDoc[], fromFolder: IDocsFolder, toFolder: IDocsFolder): Observable<void> {
    const url = this.urlJoin(this.apiPath, `/api/v1/matters/${matterId}/folders`);
    const message = {
      fromFolderId: originalId(fromFolder),
      toFolderId: originalId(toFolder),
      docs: documents?.map(originalId) || [],
    };
    return this.http.put<void>(url, message);
  }

  moveFolder(matterId: string, folder: IDocsFolder, toFolder: IDocsFolder): Observable<void> {
    const queryUrl = `?parentId=${originalId(toFolder)}`;
    const url = this.urlJoin(this.apiPath, `/api/v1/matters/${matterId}/folders`, originalId(folder), queryUrl);
    return this.http.put<void>(url, undefined);
  }

  rename(matterId: string, folder: IDocsFolder, name: string): Observable<void> {
    const folderStatus = this.validateFolder(matterId, name, folder.parent);
    if (folderStatus !== FolderValidation.NoError) {
      return observableThrowError(folderStatus);
    }

    const url = this.urlJoin(this.apiPath, `/api/v1/matters/${matterId}/folders`);
    const message = [
      {
        folderId: originalId(folder),
        folderName: name,
        parentId: originalId(folder.parent),
      },
    ];
    return this.http.post<void>(url, message);
  }

  private validateFolder(matterId: string, name: string, parent?: IDocsFolder): FolderValidation {
    if (matterId === undefined || name === undefined || parent === undefined) {
      return FolderValidation.FolderBadInfo;
    }

    const folder: IDocsFolder = parent.folders?.find((f: IDocsFolder): boolean => f.name === name);
    return !!folder ? FolderValidation.FolderDuplicated : FolderValidation.NoError;
  }
}

const originalId = (correspondence: IDoc | IDocsFolder): string => {
  if (!correspondence || correspondence.id === RootFolderId) {
    return null;
  }
  return correspondence.id;
};
