import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of, throwError } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  filter as rxjsFilter,
  withLatestFrom,
  delayWhen,
} from 'rxjs/operators';

import {
  selectCardListModalCardFilterType,
  selectCardListModalCardGuidsForFiltering,
  selectCardListModalSelectedCardFilter,
} from '@app/features/+card/store/selectors';
import { ECardFilterType, ECardType, ICardFilter, ICardListEntry } from '@app/features/+card/models';
import {
  CardDetailsService,
  CardFiltersService,
  CardListModalService,
  CardListStorageService,
} from '@app/features/+card/services';
import { selectCurrentMatter, selectCurrentMatterCore, selectCurrentMatterId, selectStaffList } from '@app/core/store';
import {
  selectAllContacts,
  selectCurrentDetailEntry,
  selectCurrentMatterCardEntries,
} from '@app/features/+matter-details/store';
import { MatterDetailsService } from '@app/features/+matter-details/services';
import * as cardModalActions from '@app/features/+card/store/actions/card-modal';
import * as cardListModalActions from '../actions/card-list-modal';
import * as matterCardActions from '@app/features/+card/store/actions/matter-card';
import { IMatterCard } from '@app/shared/models';
import { Staff } from '@app/shared/models';
import { AppApiService } from '@app/core/api';
import { TranslateService } from '@ngx-translate/core';
import { EventBusService, PlatformService, StaffService, StartupService } from '@app/core/services';
import { OutlookContact } from '@app/features/+matter-details/models';
import { ESiriusEvents } from '@app/core/models';
import { combineLatest } from 'rxjs';
import { selectRoot } from '@app/features/+correspondence/store/selectors';
import { selectCardFiltersDefaultFilters } from '@app/core/store/selectors/card-filters.selectores';
import { isEmptyArray, uniqBy } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class CardListModalEffect {

  setUpCardEntries$: any = createEffect(() => this.actions$.pipe(
    ofType(cardListModalActions.CardListModalActionTypes.SET_UP_CARD_ENTRIES),
    rxjsFilter(() => !!this.platformSvc.isBrowser),
    delayWhen(() =>
      this.store.pipe(
        select(selectCardFiltersDefaultFilters),
        rxjsFilter((filters) => !!filters && filters.length > 0)
      )
    ),
    withLatestFrom(
      this.store.pipe(select(selectCardListModalCardFilterType)),
      this.store.pipe(select(selectCardListModalSelectedCardFilter)),
      this.store.pipe(select(selectCardListModalCardGuidsForFiltering))
    ),
    switchMap(
      ([action, modalFilterType, filter, cardGuidsForFiltering]: [
        cardListModalActions.SetUpCardEntries,
        ECardFilterType,
        ICardFilter,
        { includeAllCardOption: boolean; cardGuids: string[] }
      ]) => {
        const filterType = filter && filter.type ? filter.type.toLowerCase() : '';
        const tableId = filter ? filter.id || '' : '';
        const defaultTableId = filterType === 'all' ? undefined : tableId;
        const { isEmailRecipient } = action.payload;

        switch (filterType) {
          case 'outlook':
            return this.store.pipe(
              select(selectAllContacts),
              map((contacts: OutlookContact[]) =>
                this.cardListModalSvc.getCardEntryFromSource(filterType, undefined, contacts)
              )
            );

          case 'staff':
            return this.store.pipe(
              select(selectStaffList),
              map((staffs: Staff[]) => {
                let onlyActiveStaffs = staffs?.filter((s) => s.deleteCode === 0) || [];
                if (modalFilterType === ECardFilterType.AllStaff) {
                  onlyActiveStaffs = [this.staffSvc.createStaffListOptionAll(), ...onlyActiveStaffs];
                } else {
                  // status = 0 means the staff is in current staff category.
                  onlyActiveStaffs = onlyActiveStaffs.filter((o) => o.status === 0);
                }
                return this.cardListModalSvc.getCardEntryFromSource(filterType, tableId, onlyActiveStaffs);
              })
            );

          case 'matter':
            return isEmailRecipient
              ? this.store.pipe(
                  select(selectCurrentMatterCardEntries),
                  map((cards: IMatterCard[]) =>
                    this.cardListModalSvc.getCardEntryFromSource('matter-email', tableId, cards)
                  )
                )
              : // would use dexie.js bulkGet() when dexie.js update to version 3
                forkJoin([
                  this.cardListStorageSvc.getAll().pipe(take(1)),
                  this.store.pipe(select(selectCurrentMatter), take(1)),
                ]).pipe(
                  map((data) => {
                    const [cards, currentMatter] = data;
                    const relatedCardEntries = this.matterDetailsSvc.getRelatedCardEntries(currentMatter, cards);
                    return this.cardListModalSvc.getCardEntryFromSource(filterType, tableId, relatedCardEntries);
                  })
                );

          case 'suggestedstaff':
            return combineLatest([
              this.store.pipe(select(selectRoot)),
              this.store.pipe(select(selectStaffList)),
              this.store.pipe(select(selectCurrentMatterCore)),
            ]).pipe(
              rxjsFilter(
                ([correspondenceRoot, staffList, matterCore]) => !!correspondenceRoot && !!staffList && !!matterCore
              ),
              map(([correspondenceRoot, staffList, matterCore]) => {
                const { fullDocuments } = correspondenceRoot;
                const currentUser = this._startupSvc.userDetails.staffId;
                const relevantUsers = fullDocuments && fullDocuments.filter((d) => !!d.userId);
                const createdByStaffList = staffList.filter(
                  (staff) => relevantUsers && relevantUsers.map((u) => u.userId).includes(staff.userId)
                );
                const { credit, personResponsible, personActing, personAssisting } = matterCore;
                const roleMapping = getRoleMapping(credit, personResponsible, personActing, personAssisting);
                const source = staffList.filter((staff) =>
                  [credit, personResponsible, personActing, personAssisting].includes(staff.__id)
                );
                const suggestedStaffSource = uniqBy(
                  [
                    ...source.map((s) => ({ ...s, roles: attachRolesToStaff(s.__id, roleMapping) })),
                    ...createdByStaffList,
                  ],
                  '__id'
                ).filter((ss) => ss.__id !== currentUser);
                if (isEmptyArray(suggestedStaffSource)) {
                  this.store.dispatch(new cardListModalActions.SetSelectedCardFilterType({ type: 'Staff' }));
                }
                return this.cardListModalSvc.getCardEntryFromSource(
                  isEmptyArray(suggestedStaffSource) ? 'staff' : filterType,
                  tableId,
                  isEmptyArray(suggestedStaffSource) ? staffList : suggestedStaffSource
                );
              })
            );

          case 'cardguidarray':
            const cardGuids = cardGuidsForFiltering?.cardGuids;
            return new Promise(async (resolve) => {
              const result = await this.cardListStorageSvc.getByIds(cardGuids);
              if (cardGuids.length > 1 && cardGuidsForFiltering?.includeAllCardOption) {
                // All card option use when necessary.
                // e.g. finalized invoice (split mode) has option to select debtor before printing/email the copy.
                const allCardsOption = {
                  cardId: 'all',
                  fullName: 'All',
                  shortName: 'All',
                  type: 'People',
                  isSupplier: false,
                  email: undefined,
                  persons: ['all'],
                  webAddressList: [],
                  phoneNumberList: [],
                  addressList: [],
                  roles: undefined,
                  phone: undefined,
                  address: undefined,
                  suburb: '',
                } as ICardListEntry;
                return resolve([allCardsOption, ...result]);
              }

              return resolve([...result]);
            });

          default:
            if (modalFilterType === ECardFilterType.Email) {
              const entries$ = this.cardListModalSvc.getAllByEmailUseCase(true);
              return entries$.pipe(
                map((cards) =>
                  this.cardFiltersSvc.applyFilterConditions(cards, defaultTableId, true, false, filterType)
                ),
                map((cards) => this.cardListModalSvc.mapCardsWithAddress(cards))
              );
            } else {
              return this.cardListStorageSvc.getAll().pipe(
                map((cards) =>
                  this.cardFiltersSvc.applyFilterConditions(cards, defaultTableId, true, false, filterType)
                ),
                map((cards) => this.cardListModalSvc.mapCardsWithAddress(cards))
              );
            }
        }
      }
    ),
    mergeMap((cards: ICardListEntry[]) => [new cardListModalActions.StoreCardEntries({ cardEntries: cards })])
  ));


  saveMatterCard$: any = createEffect(() => this.actions$.pipe(
    ofType<cardListModalActions.SaveMatterCard>(cardListModalActions.CardListModalActionTypes.SAVE_MATTER_CARD),
    rxjsFilter((action: cardListModalActions.SaveMatterCard) => !!action.payload.cardEntry),
    switchMap((action: cardListModalActions.SaveMatterCard) => {
      const { cardEntry } = action.payload;
      const { cardId } = cardEntry;
      return this.cardDetailsSvc.loadCard(cardId).pipe(
        take(1),
        map((cardDetails) => [action, this.cardDetailsSvc.createFormValue(cardDetails)]),
        catchError((err) => {
          this._eventBus.emit({
            name: ESiriusEvents.ShowToastr,
            value: {
              type: 'error',
              title: 'Failure',
              message: 'Unable to load card details.',
            },
          });
          return throwError(err); // re-throw error so the next rxjs operators would not be triggered
        })
      );
    }),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatterId)),
      this.store.pipe(select(selectCurrentDetailEntry)),
      ([action, formValue], matterId, matterCard) => ({
          isNew: action.payload.isNewMatterCard,
          matterId,
          matterCard,
          formValue,
        })
    ),
    switchMap((data: any) => {
      const { isNew, matterId, matterCard, formValue } = data;
      return this.matterDetailsSvc.saveMatterCard(matterId, matterCard, formValue, isNew).pipe(
        mergeMap(() => [
          new cardModalActions.SaveSucceed({
            message: this.translateSvc.instant('Matter.Card.Create.Success.Message'),
          }),
        ]),
        catchError((error) =>
          of(
            new cardModalActions.SaveFail({
              error,
              message: this.translateSvc.instant('Matter.Card.Create.Error.Message'),
            })
          )
        )
      );
    })
  ));


  selectDebtorCard$: any = createEffect(() => this.actions$.pipe(
    ofType<cardListModalActions.SelectorDebtorCard>(cardListModalActions.CardListModalActionTypes.SELECTOR_DEBTOR_CARD),
    rxjsFilter((action: cardListModalActions.SelectorDebtorCard) => !!action.payload.cardEntry),
    switchMap((action: cardListModalActions.SelectorDebtorCard) => {
      const { cardEntry } = action.payload;
      const { cardId } = cardEntry;
      return this.cardDetailsSvc.loadCard(cardId).pipe(
        take(1),
        map((cardDetails) => this.cardDetailsSvc.createFormValue(cardDetails)),
        catchError((err) => {
          this._eventBus.emit({
            name: ESiriusEvents.ShowToastr,
            value: {
              type: 'error',
              title: 'Failure',
              message: 'Unable to load card details.',
            },
          });
          return throwError(err); // re-throw error so the next rxjs operators would not be triggered
        })
      );
    }),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatterId)),
      this.store.pipe(select(selectCurrentDetailEntry)),
      (formValue, matterId, matterCard) => ({
        formValue,
        matterId,
        matterCard,
      })
    ),
    switchMap((data: any) => {
      const { formValue, matterId } = data;
      let matterCard = data.matterCard;

      return this.matterDetailsSvc.setDebtorCard(matterId, matterCard as IMatterCard, formValue).pipe(
        mergeMap((savedMatterCard: IMatterCard) => {
          if (formValue.type !== ECardType.People) {
            matterCard = {
              ...matterCard,
              reference: formValue.matterCardReference,
            };
          }
          return [
            new matterCardActions.SetDebtorCardSucceed({
              savedMatterCard,
              matterCard,
              formValue,
            }),
          ];
        }),
        catchError((error) =>
          of(
            new cardModalActions.SaveFail({
              error,
              message: this.translateSvc.instant('Matter.Card.Debtor.Add.Error.Message'),
            })
          )
        )
      );
    })
  ));

  /* eslint-enable */

  closeCardListModal$: any = createEffect(() => this.actions$.pipe(
    ofType(cardListModalActions.CardListModalActionTypes.CLOSE),
    tap(() => {
      this.appApiSvc.clearCurrentModal(null, true);
    })
  ), { dispatch: false });


  triggerCloseCardListModal$: any = createEffect(() => this.actions$.pipe(
    ofType(cardListModalActions.CardListModalActionTypes.SAVE_MATTER_CARD),
    switchMap(() => [new cardListModalActions.CloseCardListModal(null)])
  ));

  constructor(
    private actions$: Actions,
    private platformSvc: PlatformService,
    private cardDetailsSvc: CardDetailsService,
    private cardFiltersSvc: CardFiltersService,
    private cardListModalSvc: CardListModalService,
    private cardListStorageSvc: CardListStorageService,
    private matterDetailsSvc: MatterDetailsService,
    private appApiSvc: AppApiService,
    private translateSvc: TranslateService,
    private _startupSvc: StartupService,
    private staffSvc: StaffService,
    private _eventBus: EventBusService,
    private store: Store<any>
  ) {}
}

const attachRolesToStaff = (staffId: string, roleMapping: any[]): string[] => roleMapping.map((role) => (role.id === staffId ? role.name : '')).filter((x) => !!x);

const getRoleMapping = (credit: string, responsible: string, acting: string, assisting: string) => {
  const rolesMapping = [
    { code: 'credit', name: 'Credit', id: credit },
    { code: 'personResponsible', name: 'Responsible', id: responsible },
    { code: 'personActing', name: 'Acting', id: acting },
    { code: 'personAssisting', name: 'Assisting', id: assisting },
  ];
  return rolesMapping;
};
