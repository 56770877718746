import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { combineLatest, map, Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { EPermissionType } from '@app/core/constants';
import { selectCurrentMatterId, selectCurrentStaffAutoTime } from '@app/core/store';
import { selectAutoTimeBillable } from '../../store/selectors';
import { AutoTimeClearTask, AutoTimeEndTask, AutoTimeTrySetBillable, AutoTimeTryStartTask } from '../../store/actions';
import { ofType } from '@ngrx/effects';
import { AutoTimeService } from '../../services/autotime.service';
import { AutoTimeEndTaskActionTypes, AutoTimeStartTaskActionTypes } from '../../models';

@Component({
  selector: 'sc-autotime-header',
  templateUrl: './autotime-header.component.html',
})
export class AutoTimeHeaderComponent implements OnInit, OnDestroy {

  @Input() permissionsExcept: (typeof EPermissionType)[];

  visible$: Observable<boolean>;
  enabled$: Observable<boolean>;

  private _unsub = new Subject<void>();

  setBillableState(enabled: boolean) {
    this._store.dispatch(new AutoTimeTrySetBillable(enabled));
  }

  ngOnInit(): void {
    this.visible$ = combineLatest([
      this._store.pipe(select(selectCurrentStaffAutoTime)),
      this._store.pipe(select(selectCurrentMatterId))
    ]).pipe(
      map(([autoTime, matterId]) => !!autoTime && !!matterId)
    );
    this.enabled$ = this._store.pipe(select(selectAutoTimeBillable));

    this.subscribeToActionListener();
  }

  ngOnDestroy(): void {
    this._unsub.next();
    this._unsub.complete();
  }

  constructor(
    private _actionListener$: ActionsSubject,
    private _store: Store<any>,
    private _autotimeSvc: AutoTimeService,

  ) {}

  private subscribeToActionListener() {
    this._actionListener$.pipe(
      ofType(...AutoTimeStartTaskActionTypes),
      takeUntil(this._unsub)
    ).subscribe((action) => {
      const payload = this._autotimeSvc.getStartTaskPayload(action);
      if (!payload) {
        return;
      }

      this._store.dispatch(new AutoTimeTryStartTask(payload));
    });

    this._actionListener$.pipe(
      ofType(...AutoTimeEndTaskActionTypes),
      takeUntil(this._unsub)
    ).subscribe((action) => {
      const payload = this._autotimeSvc.getEndTaskPayload(action);
      if (!payload) {
        this._store.dispatch(new AutoTimeClearTask());
        return;
      }

      this._store.dispatch(new AutoTimeEndTask(payload));
    });
  }
}
