import { Component, HostBinding, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AggridEditorAbstract } from '@app/sharedaggrid/components/aggrid-editor/aggrid-editor.abstract';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-text-editor-cell',
  templateUrl: './text-editor-cell.component.html',
})
export class TextEditorCellComponent extends AggridEditorAbstract implements ICellEditorAngularComp {
  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;

  private _params: ICellEditorParams;
  public editForm: FormGroup;
  public delayInputInMs = 200;

  constructor(private _formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  @HostBinding('class.w-100')
  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this._params = params;
    const defaultValue =
      (isFunction(params['getContent']) ? params['getContent'](params.node.data) : params.value) || '';
    this.editForm.patchValue({ name: defaultValue });

    if (isFunction(params['getInputDelayByMs'])) {
      this.delayInputInMs = params['getInputDelayByMs']();
    }
  }

  getValue(): any {
    return this.editForm.get('name').value;
  }

  focusIn() {
    this.input.element.nativeElement.firstElementChild.focus();
  }

  stopEditing(cancel?: boolean): void {
    this._params.api.stopEditing(cancel);
  }

  // keydown(event: KeyboardEvent): void {
  //   event.stopPropagation();
  //   const eventKey = event.key;
  //   switch (eventKey) {
  //     case AggridKeyboardCode['enter']: // Enter should stop and save editting
  //       this.stopEditing();
  //       return;
  //     case AggridKeyboardCode['esc']: // Escape should stop and cancel editting
  //       this.stopEditing(true);
  //       return;
  //   }
  // }

  private createForm(): void {
    this.editForm = this._formBuilder.group({
      name: '',
    });
  }
}
