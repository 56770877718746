import { AdvancedSearchData } from '../../models';
import * as actions from '../actions/advanced-search';

export interface State {
  onClose: boolean;
  advancedSearchData: AdvancedSearchData;
}

export const initialState: State = {
  onClose: false,
  advancedSearchData: null,
};

export const reducer = (state = initialState, action: actions.AdvancedSearchModalActions): State => {
  switch (action.type) {
    case actions.ADVANCED_SEARCH_MODAL_ON_OPEN: {
      return {
        ...state,
        onClose: false,
        advancedSearchData: action.payload,
      };
    }

    case actions.ADVANCED_SEARCH_MODAL_ON_CLOSE: {
      return {
        ...state,
        onClose: true,
        advancedSearchData: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const selectOnClose = (state: State) => state.onClose;
export const selectAdvancedSearchData = (state: State) => state.advancedSearchData;
