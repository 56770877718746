import { Action } from '@ngrx/store';
import { RecurringMatterListItem, RenameRecurringMatterEvent, RecurringMatterUiInfo } from '../../models';
import { INewMatterParams } from '@app/features/+matter-list/models';

export const LIST_RECURRING_MATTERS_START = '[Recurring matter list] List start';
export const LIST_RECURRING_MATTERS_SUCCESS = '[Recurring matter list] List success';
export const LIST_RECURRING_MATTERS_FAILURE = '[Recurring matter list] List failure';

export const RECURRING_MATTER_SELECT_ID = '[Recurring matter list] select recurring matter id';

export const CREATE_MATTER_FROM_RECURRING_MATTER_START =
  '[Recurring matter list] Create matter from recurring matter start';
export const CREATE_MATTER_FROM_RECURRING_MATTER_SUCCESS =
  '[Recurring matter list] Create matter from recurring matter success';
export const CREATE_MATTER_FROM_RECURRING_MATTER_FAILURE =
  '[Recurring matter list] Create matter from recurring matter failure';

export const DELETE_RECURRING_MATTER_START = '[Recurring matter list] delete start';
export const DELETE_RECURRING_MATTER_SUCCESS = '[Recurring matter list] delete success';
export const DELETE_RECURRING_MATTER_FAILURE = '[Recurring matter list] delete failure';

export const UNDELETE_RECURRING_MATTER_START = '[Recurring matter list] undelete start';
export const UNDELETE_RECURRING_MATTER_SUCCESS = '[Recurring matter list] undelete success';
export const UNDELETE_RECURRING_MATTER_FAILURE = '[Recurring matter list] undelete failure';

export const RENAME_RECURRING_MATTER_START = '[Recurring matter list] rename start';
export const RENAME_RECURRING_MATTER_SUCCESS = '[Recurring matter list] rename success';
export const RENAME_RECURRING_MATTER_FAILURE = '[Recurring matter list] rename failure';

export const RECURRING_MATTER_CHECK_NAME = '[Create Recurring matter] check create name';
export const RECURRING_MATTER_CREATE_SET_NAME = '[Create Recurring matter] set create name';

export const RECURRING_MATTER_CREATE_START = '[Create Recurring matter] create start';
export const RECURRING_MATTER_CREATE_SUCCESS = '[Create Recurring matter] create success';
export const RECURRING_MATTER_CREATE_FAILURE = '[Create Recurring matter] create failure';

export const RECURRING_MATTERS_CLOSE_DIALOG = '[Recurring matter] close dialog';
export const RECURRING_MATTERS_SET_UI_INFO = '[Recurring matter] set ui info';

export const RECURRING_MATTER_RESET = '[Recurring matter] reset';
export const SELECT_RECURRING_MATTER_CLIENT_CARD = '[Recurring matter] select recurring matter client card';
export const SELECT_RECURRING_MATTER_CLIENT_CARD_SUCCESS =
  '[Recurring matter] select recurring matter client card success';
export const SELECT_RECURRING_MATTER_CLIENT_CARD_FAILURE =
  '[Recurring matter] select recurring matter client card failure';
export class ListRecurringMattersStart implements Action {
  readonly type = LIST_RECURRING_MATTERS_START;
  constructor(public payload: any) {}
}

export class ListRecurringMattersSuccess implements Action {
  readonly type = LIST_RECURRING_MATTERS_SUCCESS;
  constructor(public payload: RecurringMatterListItem[]) {}
}

export class ListRecurringMattersFailure implements Action {
  readonly type = LIST_RECURRING_MATTERS_FAILURE;
  constructor(public payload: any) {}
}

export class SelectRecurringMatterId implements Action {
  readonly type = RECURRING_MATTER_SELECT_ID;
  constructor(public payload: string) {}
}

export class CreateMatterFromRecurringMatterStart implements Action {
  readonly type = CREATE_MATTER_FROM_RECURRING_MATTER_START;
  constructor(public payload: INewMatterParams) {}
}

export class CreateMatterFromRecurringMatterSuccess implements Action {
  readonly type = CREATE_MATTER_FROM_RECURRING_MATTER_SUCCESS;
  constructor(public payload: string) {}
}

export class CreateMatterFromRecurringMatterFailure implements Action {
  readonly type = CREATE_MATTER_FROM_RECURRING_MATTER_FAILURE;
  constructor(public payload: any) {}
}

export class DeleteRecurringMatterStart implements Action {
  readonly type = DELETE_RECURRING_MATTER_START;
  constructor(public payload: RecurringMatterListItem) {}
}

export class DeleteRecurringMatterSuccess implements Action {
  readonly type = DELETE_RECURRING_MATTER_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteRecurringMatterFailure implements Action {
  readonly type = DELETE_RECURRING_MATTER_FAILURE;
  constructor(public payload: any) {}
}

export class UnDeleteRecurringMatterStart implements Action {
  readonly type = UNDELETE_RECURRING_MATTER_START;
  constructor(public payload: RecurringMatterListItem) {}
}

export class UnDeleteRecurringMatterSuccess implements Action {
  readonly type = UNDELETE_RECURRING_MATTER_SUCCESS;
  constructor(public payload: any) {}
}

export class UnDeleteRecurringMatterFailure implements Action {
  readonly type = UNDELETE_RECURRING_MATTER_FAILURE;
  constructor(public payload: any) {}
}

export class RenameRecurringMatterStart implements Action {
  readonly type = RENAME_RECURRING_MATTER_START;
  constructor(public payload: RenameRecurringMatterEvent) {}
}

export class RenameRecurringMatterSuccess implements Action {
  readonly type = RENAME_RECURRING_MATTER_SUCCESS;
  constructor(public payload: any) {}
}

export class RenameRecurringMatterFailure implements Action {
  readonly type = RENAME_RECURRING_MATTER_FAILURE;
  constructor(public payload: any) {}
}

export class RecurringMatterSetCreateName implements Action {
  readonly type = RECURRING_MATTER_CREATE_SET_NAME;
  constructor(public payload: string) {}
}

export class CheckRecurringMatterCreateName implements Action {
  readonly type = RECURRING_MATTER_CHECK_NAME;
  constructor(public payload: string) {}
}

export class RecurringMatterCreateStart implements Action {
  readonly type = RECURRING_MATTER_CREATE_START;
  constructor(public payload: INewMatterParams) {}
}

export class RecurringMatterCreateSuccess implements Action {
  readonly type = RECURRING_MATTER_CREATE_SUCCESS;
  constructor(public payload: any) {}
}

export class RecurringMatterCreateFailure implements Action {
  readonly type = RECURRING_MATTER_CREATE_FAILURE;
  constructor(public payload: string) {}
}

export class RecurringMatterCloseDialog implements Action {
  readonly type = RECURRING_MATTERS_CLOSE_DIALOG;
  constructor(public payload: any) {}
}

export class RecurringMatterSetUiInfo implements Action {
  readonly type = RECURRING_MATTERS_SET_UI_INFO;
  constructor(public payload: RecurringMatterUiInfo) {}
}

export class RecurringMatterReset implements Action {
  readonly type = RECURRING_MATTER_RESET;
  constructor(public payload: any) {}
}

export class SelectRecurringMatterClientCard implements Action {
  readonly type = SELECT_RECURRING_MATTER_CLIENT_CARD;
  constructor(public payload: any) {}
}

export class SelectRecurringMatterClientCardSuccess implements Action {
  readonly type = SELECT_RECURRING_MATTER_CLIENT_CARD_SUCCESS;
  constructor(public payload: any) {}
}

export class SelectRecurringMatterClientCardFailure implements Action {
  readonly type = SELECT_RECURRING_MATTER_CLIENT_CARD_FAILURE;
  constructor(public payload: any) {}
}

export type RecurringMatterListActions =
  | ListRecurringMattersStart
  | ListRecurringMattersSuccess
  | ListRecurringMattersFailure
  | SelectRecurringMatterId
  | CreateMatterFromRecurringMatterStart
  | CreateMatterFromRecurringMatterSuccess
  | CreateMatterFromRecurringMatterFailure
  | DeleteRecurringMatterStart
  | DeleteRecurringMatterSuccess
  | DeleteRecurringMatterFailure
  | UnDeleteRecurringMatterStart
  | UnDeleteRecurringMatterSuccess
  | UnDeleteRecurringMatterFailure
  | RenameRecurringMatterStart
  | RenameRecurringMatterSuccess
  | RenameRecurringMatterFailure
  | RecurringMatterSetCreateName
  | RecurringMatterCreateStart
  | RecurringMatterCreateSuccess
  | RecurringMatterCreateFailure
  | RecurringMatterCloseDialog
  | RecurringMatterSetUiInfo
  | CheckRecurringMatterCreateName
  | RecurringMatterReset
  | SelectRecurringMatterClientCard
  | SelectRecurringMatterClientCardSuccess
  | SelectRecurringMatterClientCardFailure;
