export interface Config {
  oauth: Oauth;
  keys: Keys;
  endpoint: Endpoint;
}

export interface Endpoint {
  account: Account;
  accounting: Account;
  automation: Account;
  bankrec: Account;
  bylawyers: string;
  calendar: Account;
  cdn: Account;
  docs: Account;
  infotrack: Infotrack;
  infotrackSettings: string;
  leaponline: Account;
  myobweb: Account;
  pdfservice: Account;
  quickbook: Account;
  reporting: Account;
  reportingApi: Account;
  schema: Account;
  settings: string;
  wopi: Account;
  xero: Account;
  auth: string;
  matteraddin: MatterAddin;
}

export interface Infotrack {
  au: string;
  uk: string;
  us: string;
}

export interface Account {
  au: string;
  uk?: any;
  us?: any;
}

export interface Keys {
  pnPublish: string;
  pnSubscribe: string;
}

export interface Oauth {
  endpoint: string;
  user_info_uri: string;
  provider_id: string;
  client_id: string;
}

export interface MatterAddin {
  au: string;
  uk: string;
  us: string;
}

export enum Regions {
  AU = 'au',
  US = 'us',
  UK = 'uk',
  CA = 'ca',
  IE = 'ie',
  NZ = 'nz',
}

export enum DateFormatType {
  A = 0, // 'M/d/yy', 'y/MM/dd'
  B = 1, // 'mm/dd/yy', 'dd/mm/yy', 'y/mm/dd'
  C = 2, // 'mm/dd/yyyy', 'dd/mm/yyyy', 'y/mm/dd'
  D = 3, // 'EE MMM d y', 'EE d MMM y', 'EE y/MM/dd',
  E = 4, // 'MMM d y', 'd MMM y', 'y/MM/dd',
}
