import { ISortInfo } from '@app/shared/models';
import { IMatterListEntry, IMatterListUIInfo } from '../../models';
import { AdvancedSearchMode } from '../../constants';
import * as actions from '../actions/matter-list';
import * as recurringMatterListActions from '@app/features/+recurring-matter-list/store/actions';

export interface State {
  selectedMatterGuid: string;
  loaded: boolean;
  loading: boolean;
  newMatterSaving: boolean;
  sortInfo: ISortInfo;
  uiInfo: IMatterListUIInfo;
  cloudSearchResults: IMatterListEntry[];
}

export const initialState: State = {
  selectedMatterGuid: '',
  loaded: false,
  loading: false,
  newMatterSaving: false,
  sortInfo: {
    colId: 'fileNumber',
    sort: 'asc',
  },
  uiInfo: {
    showState: false,
    showDeleted: false,
    showClientShortName: true,
    showClientLongName: false,
  },
  cloudSearchResults: [],
};

export const reducer = (
  state = initialState,
  action:
    | actions.MatterListApiActions
    | actions.MatterListDbActions
    | recurringMatterListActions.RecurringMatterListActions
): State => {
  switch (action.type) {
    case actions.INIT_LIST_MATTERS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.INIT_LIST_MATTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.INIT_LIST_MATTERS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.CLOUD_SEARCH_SUCCESS: {
      const docsSearchModes = [AdvancedSearchMode.AdvancedSearch, AdvancedSearchMode.QuickSearch];
      const isDocsSearch = docsSearchModes.findIndex((t) => t === action.payload.searchMode) !== -1;
      const cloudSearchResults = isDocsSearch ? (action.payload.data as IMatterListEntry[]) : [];

      return {
        ...state,
        cloudSearchResults,
        loading: false,
      };
    }

    case actions.MATTERS_SAVE_DB_SUCCESS:
      return {
        ...state,
        loaded: true,
        newMatterSaving: state.newMatterSaving ? false : state.newMatterSaving,
      };

    case actions.CLOUD_SEARCH_START:
    case actions.DELETE_MATTER_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.DELETE_MATTER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.LIST_MATTERS_FAILURE:
    case actions.DELETE_MATTER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case recurringMatterListActions.CREATE_MATTER_FROM_RECURRING_MATTER_START:
    case actions.CREATE_MATTER_START: {
      return {
        ...state,
        loading: true,
        newMatterSaving: true,
      };
    }

    case actions.CREATE_MATTER_LOADING_STATUS: {
      return {
        ...state,
        loading: action.payload || false,
      };
    }

    case actions.CREATE_MATTER_FAILURE: {
      return {
        ...state,
        loading: false,
        newMatterSaving: false,
      };
    }

    case actions.SET_META: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const selectLoading = (state: State) => state.loading;
export const selectLoaded = (state: State) => state.loaded;
export const selectUIInfo = (state: State) => state.uiInfo;
export const selectSortInfo = (state: State) => state.sortInfo;
export const selectCurrentMatterGuid = (state: State) => state.selectedMatterGuid;
export const selectNewMatterSaving = (state: State) => state.newMatterSaving;
export const selectCloudSearchResults = (state: State) => state.cloudSearchResults;
