import * as actions from '../actions';
import { IDoc } from '@app/shared/models';
import { CorrespondenceDownloadProgress } from '../../models';

export interface State {
  loading: boolean;
  error: any | string | null;
  dbSaveStatus: string;
  download: CorrespondenceDownloadProgress;
  checkedCorrespondences: IDoc[];
  deselectDocIds: string[];
  clearGridSelection: boolean;
  extractedAttatchments: string[];
}

export const initialState: State = {
  loading: false,
  error: null,
  dbSaveStatus: '',
  download: undefined,
  checkedCorrespondences: [],
  deselectDocIds: [],
  clearGridSelection: undefined,
  extractedAttatchments: null,
};

export const reducer = (
  state = initialState,
  action: actions.CorrespondenceApiActions | actions.CorrespondenceActions
): State => {
  switch (action.type) {
    case actions.TOGGLE_CORRESPONDENCE_LOADING: {
      return {
        ...state,
        loading: action.payload === undefined ? !state.loading : action.payload,
      };
    }

    case actions.STORE_CORRESPONDENCE_SELECTION: {
      return {
        ...state,
        checkedCorrespondences: action.payload,
      };
    }

    case actions.DESELECT_DOCUMENT_IN_GRID: {
      return {
        ...state,
        deselectDocIds: action.payload,
      };
    }

    case actions.CLEAR_CORRESPONDENCE_SELECTION: {
      return {
        ...state,
        clearGridSelection: action.payload,
      };
    }

    case actions.EDIT_DOCUMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.RENAME_CORRESPONDENCE_FAILURE:
    case actions.DELETE_CORRESPONDENCE_FAILURE:
    case actions.UNDELETE_CORRESPONDENCE_FAILURE:
    case actions.TOGGLE_PIN_CORRESPONDENCE_FAILURE:
    case actions.EDIT_DOCUMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case actions.RENAME_CORRESPONDENCE_START:
    case actions.DELETE_CORRESPONDENCE_START:
    case actions.UNDELETE_CORRESPONDENCE_START:
    case actions.TOGGLE_PIN_CORRESPONDENCE_START:
    case actions.EDIT_DOCUMENT_START: {
      return {
        ...state,
        error: null,
      };
    }

    case actions.DOCUMENT_DOWNLOAD:
      return {
        ...state,
        download: { loading: true } as CorrespondenceDownloadProgress,
      };

    case actions.DOCUMENT_DOWNLOAD_SUCCESS:
      const downloadSuccessValue = { ...state.download };
      downloadSuccessValue.loading = false;
      return {
        ...state,
        download: downloadSuccessValue,
      };

    case actions.DOCUMENT_DOWNLOAD_INPROGRESS:
      const downloadInprogressValue = { ...state.download };
      downloadInprogressValue.fileName = action.payload.fileName;
      downloadInprogressValue.url = action.payload.url;
      downloadInprogressValue.fileNameOnly = action.payload.fileNameOnly;
      return {
        ...state,
        download: downloadInprogressValue,
      };

    case actions.DOCUMENT_DOWNLOAD_FAILURE:
      const downloadErrValue = { ...state.download };
      downloadErrValue.loading = false;
      return {
        ...state,
        download: downloadErrValue,
        error: action.payload,
      };

    case actions.EXTRACT_ATTACHMENT_SUCCESS:
      return {
        ...state,
        extractedAttatchments: [...action.payload],
      };

    case actions.CLEAR_EXTRACTED_ATTACHMENTS:
      return {
        ...state,
        extractedAttatchments: null,
      };

    case actions.RENAME_CORRESPONDENCE_SUCCESS:
    case actions.DELETE_CORRESPONDENCE_SUCCESS:
    case actions.UNDELETE_CORRESPONDENCE_SUCCESS:
    case actions.TOGGLE_PIN_CORRESPONDENCE_SUCCESS:
    case actions.PREVIEW_DOCUMENT:
    default: {
      return state;
    }
  }
};

export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;
export const selectDownloadUrl = (state: State) => state.download.url;
export const selectDownloadFileName = (state: State) => state.download.fileName;
export const selectCheckedCorrespondence = (state: State) => state && state.checkedCorrespondences;
export const selectClearCorrespondenceGrid = (state: State) => state.clearGridSelection;
export const selectDownloadFileNameOnly = (state: State) => state.download.fileNameOnly;
export const selectDeselectDocIds = (state: State) => state.deselectDocIds;
export const selectExtractAttachmentsSuccess = (state: State) => state.extractedAttatchments;
