import { Component, HostBinding, OnInit } from '@angular/core';
import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-grid-loading-skeleton',
  templateUrl: './grid-loading-skeleton.component.html',
  styles: [
    `
      :host {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: white;
      }
    `,
  ],
})
export class GridLoadingSkeletonComponent implements ILoadingOverlayAngularComp {
  public rowHeight = '0px';
  public headerHeight = '0px';
  public skeletonAmount = 20;

  @HostBinding('style.marginTop')
  get hostMarginTop() {
    return this.headerHeight;
  }

  constructor() {}

  agInit(params: any): void {
    this.headerHeight = getObjValue(params, 'headerHeight', this.headerHeight);
    this.rowHeight = getObjValue(params, 'rowHeight', this.rowHeight);
    this.skeletonAmount = getObjValue(params, 'skeletonAmount', this.skeletonAmount);
  }
}
