import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BankAccountService,
  PaymentAddressService,
  InflowPaymentTypeParserService,
  OutflowPaymentTypeParserService,
  TransactionNumberService,
  TaxCodeService,
} from './services';
import { EffectsModule } from '@ngrx/effects';
import { AccountingApiEffects, reducer } from './store';
import { StoreModule } from '@ngrx/store';
import { AppSlice } from '@app/core/store';
import {
  OutflowPaymentTypeComponent,
  OutflowPaymentTypeDetailsReadonlyComponent,
  InflowPaymentTypeDetailsReadonlyComponent,
  InflowPaymentTypeComponent,
  TransactionNumberComponent,
  InvoiceToolbarV2Component,
  InvoiceFooterComponent,
  ReceiptNumberComponent,
  TrustBankAccountListSelectorComponent,
  PayToComponent,
} from '@app/features/accounting/components';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import { EmailStateModule } from '@app/features/+email/email-state.module';
import { SharedAgGridModule } from '@app/sharedaggrid/sharedaggrid.module';
import { MatterListService, MatterListStorageService } from '@app/features/+matter-list/services';
import { Angulartics2Module } from 'angulartics2';
import { PrecedentListStateModule } from '../+precedent/precedent-list-state.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    Angulartics2Module,
    SharedAgGridModule,
    EmailStateModule,
    StoreModule.forFeature(AppSlice.Accounting, reducer),
    EffectsModule.forFeature([AccountingApiEffects]),
    TypeaheadModule.forRoot(),
    PrecedentListStateModule,
  ],
  declarations: [
    OutflowPaymentTypeComponent,
    OutflowPaymentTypeDetailsReadonlyComponent,
    TransactionNumberComponent,
    ReceiptNumberComponent,
    InflowPaymentTypeComponent,
    InflowPaymentTypeDetailsReadonlyComponent,
    InvoiceToolbarV2Component,
    InvoiceFooterComponent,
    TrustBankAccountListSelectorComponent,
    PayToComponent,
  ],
  exports: [
    OutflowPaymentTypeComponent,
    OutflowPaymentTypeDetailsReadonlyComponent,
    InflowPaymentTypeComponent,
    InflowPaymentTypeDetailsReadonlyComponent,
    TransactionNumberComponent,
    InvoiceToolbarV2Component,
    InvoiceFooterComponent,
    ReceiptNumberComponent,
    TrustBankAccountListSelectorComponent,
    PayToComponent,
  ],
  providers: [
    MatterListService,
    MatterListStorageService,
    BankAccountService,
    PaymentAddressService,
    TransactionNumberService,
    InflowPaymentTypeParserService,
    OutflowPaymentTypeParserService,
    TaxCodeService,
  ],
})
export class AccountingModule {}
