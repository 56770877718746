import { from as observableFrom, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DexieService, LogService, PlatformService } from '@app/core/services';
import { BaseStorageService } from '@app/shared/services';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { MatterDetailsMetaInfo } from '@app/shared/models';
import { ICardListEntry } from '@app/features/+card/models';

@Injectable()
export class MatterDetailsStorageService extends BaseStorageService<MatterDetailsMetaInfo> {
  constructor(private dexieService: DexieService, private log: LogService, private _platformSvc: PlatformService) {
    super();
    this.log.init('matter-details-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this.dexieService.table('matterDetails');
    }
  }

  getDetails(matterId: string): Observable<IMatterListEntry> {
    if (this._platformSvc.isBrowser) {
      const project = (metaInfo: MatterDetailsMetaInfo): IMatterListEntry => metaInfo.matterEntry;
      return this.getMeta(matterId).pipe(map(project));
    }
  }

  getRelatedCards(matterId: string): Observable<ICardListEntry[]> {
    if (this._platformSvc.isBrowser) {
      const project = (metaInfo: MatterDetailsMetaInfo): ICardListEntry[] => metaInfo.cardEntries;
      return this.getMeta(matterId).pipe(map(project));
    }
  }

  getMeta(matterId: string): Observable<MatterDetailsMetaInfo> {
    if (this._platformSvc.isBrowser) {
      return observableFrom(this.get(matterId));
    }
  }

  upsert(metaInfos: MatterDetailsMetaInfo[]) {
    if (this._platformSvc.isBrowser) {
      return this.bulkPut(metaInfos);
    }
  }

  public handleDatabaseError(error: any): any {
    return throwError(error);
  }
}
