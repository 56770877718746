import { from as observableFrom, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { DexieService, LogService, PlatformService } from '@app/core/services';
import { BaseStorageService } from '@app/shared/services';
import { CorrespondenceListMetaInfo } from '../models';
import { pick } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class CorrespondenceListMetaStorageService extends BaseStorageService<CorrespondenceListMetaInfo> {
  constructor(private dexieService: DexieService, private log: LogService, private _platformSvc: PlatformService) {
    super();
    this.log.init('correspondence-folder-meta-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this.dexieService.table('correspondenceFolderMeta');
    }
  }

  getMeta(matterId: string): Observable<CorrespondenceListMetaInfo> {
    if (this._platformSvc.isBrowser) {
      return observableFrom(this.get(matterId));
    }
  }

  upsertMeta(matterId: string, changedMeta: Partial<CorrespondenceListMetaInfo>) {
    if (this._platformSvc.isBrowser) {
      return this.get(matterId).then((metaInfo: CorrespondenceListMetaInfo) => {
        this.put(
          pick({ ...metaInfo, ...changedMeta, matterId }, [
            'matterId',
            'selectedFolderId',
            'showDeleted',
            'sortInfo',
            'uiInfo',
          ])
        );
      });
    }
  }

  clearMeta(): Observable<void> {
    if (this._platformSvc.isBrowser) {
      return observableFrom(this.clear());
    }
  }

  removeMeta(matterId: string): Observable<void> {
    if (this._platformSvc.isBrowser) {
      return observableFrom(this.remove(matterId));
    }
  }

  public handleDatabaseError(error: any): any {
    return throwError(error);
  }
}
