import { mergeMap, withLatestFrom, switchMap, catchError, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

// use new lettable i.e. from rxjs/operators
import { Observable, of } from 'rxjs';

import { MatterSearchService, MatterSearchStorageService } from '../../services';
import { IMatterSearchState } from '../../models';
import { AdvancedSearchMode, EMattersFilter } from '../../constants';

import * as actions from '../actions/matter-search';
import * as matterListActions from '../actions/matter-list';
import { selectMatterSearch } from '../selectors';
import { State, extractMatterSearch, initialState } from '../reducers';
import { PlatformService } from '@app/core/services';

@Injectable()
export class MatterSearchEffects {

  loadSearchQueryFromDb$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.LOAD_MATTERS_SEARCH_START),
    filter(() => this.platformService.isBrowser),
    switchMap(() =>
      this.matterSearchStorageSvc.getMeta().pipe(
        mergeMap((data) => [
          new actions.LoadMattersSearchSuccess(data),
          ...getCloudSearchActionList(data, this.matterSearchSvc),
        ]),
        catchError(() => of(new actions.SearchSaveDbStart(extractMatterSearch(initialState.matterSearch))))
      )
    )
  ));


  searchMatters$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SEARCH_MATTERS_START),
    withLatestFrom(this.store.pipe(select(selectMatterSearch)), (action, matterSearch) => matterSearch),
    switchMap((matterSearch) => [
      new actions.SearchSaveDbStart(matterSearch),
      ...getCloudSearchActionList(matterSearch, this.matterSearchSvc),
    ])
  ));


  searchMattersCloudStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SEARCH_MATTERS_CLOUD_START),
    withLatestFrom(this.store.pipe(select(selectMatterSearch)), (action, matterSearch) => ({ action, matterSearch })),
    switchMap((stateData: { action: actions.SearchMattersCloudStart; matterSearch: IMatterSearchState }) => {
      const payload = stateData.action.payload;
      if (!payload) {
        return this.matterSearchSvc
          .searchMatters(
            {
              searchText: stateData.matterSearch.searchText,
              archivedOnly: stateData.matterSearch.filterBy.includeAllMatters,
            },
            AdvancedSearchMode.QuickSearch
          )
          .pipe(
            mergeMap((data) => [
              new actions.SearchMattersSuccess(null),
              new matterListActions.CloudSearchSuccess({
                searchMode: AdvancedSearchMode.QuickSearch,
                data,
              }),
            ]),
            catchError((error) => of(new actions.SearchMattersCloudFailure(error)))
          );
      } else if (payload === EMattersFilter.AdvancedSearch) {
        return this.matterSearchSvc
          .searchMatters(
            { SearchTerms: stateData.matterSearch.advancedSearch.searchTerms },
            AdvancedSearchMode.AdvancedSearch
          )
          .pipe(
            mergeMap((data) => [
              new actions.SearchMattersSuccess(null),
              new matterListActions.CloudSearchSuccess({
                searchMode: AdvancedSearchMode.AdvancedSearch,
                data,
              }),
            ]),
            catchError((error) => of(new actions.SearchMattersCloudFailure(error)))
          );
      } else {
        return this.matterSearchSvc.searchMatters(payload, AdvancedSearchMode.AccountingSearch).pipe(
          mergeMap((data) => [
            new actions.SearchMattersSuccess(null),
            new matterListActions.CloudSearchSuccess({
              searchMode: AdvancedSearchMode.AccountingSearch,
              data,
            }),
            new actions.SearchMattersCloudSuccess({
              searchMode: AdvancedSearchMode.AccountingSearch,
              data,
            }),
          ]),
          catchError((error) => of(new actions.SearchMattersCloudFailure(error)))
        );
      }
    })
  ));


  saveSearchQueryToDb$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SEARCH_SAVE_DB_START),
    withLatestFrom(this.store.pipe(select(selectMatterSearch)), (action, matterSearch) => matterSearch),
    filter(() => this.platformService.isBrowser),
    switchMap((matterSearch: IMatterSearchState) =>
      this.matterSearchStorageSvc
        .upsertAll(matterSearch)
        .then(() => new actions.SearchSaveDbSuccess('success'))
        .catch((error) => new actions.SearchSaveDbFailure(error))
    )
  ));

  constructor(
    private actions$: Actions,
    private matterSearchSvc: MatterSearchService,
    private platformService: PlatformService,
    private matterSearchStorageSvc: MatterSearchStorageService,
    private store: Store<State>
  ) {}
}

const getCloudSearchActionList = (matterSearch: IMatterSearchState, matterSearchSvc: MatterSearchService): Action[] => {
  let filterKey = matterSearch.filterBy ? matterSearch.filterBy.filterKey : undefined;
  filterKey = matterSearchSvc.getTransformedFilterKey(filterKey);

  const isAccountingSearch = !!filterKey && filterKey !== EMattersFilter.AdvancedSearch;
  const isAdvancedSearch =
    filterKey === EMattersFilter.AdvancedSearch && matterSearch.advancedSearch.includeArchivedMatters;
  const isInMemorySearch = !isAccountingSearch && !isAdvancedSearch && !matterSearch.filterBy.includeAllMatters;

  return isInMemorySearch
    ? [new actions.SearchMattersSuccess(null)]
    : [new actions.SearchMattersCloudStart(filterKey), new matterListActions.CloudSearchStart(null)];
};
