import { IMatterSearchState } from '../../models';
import { EMattersFilter, AdvancedSearchMode, GroupByNone } from '../../constants';
import * as actions from '../actions/matter-search';
import { pick } from '@server/modules/shared/functions/common-util.functions';

export interface State extends IMatterSearchState {
  error: any | string | null;
  dbSaveStatus: string;
  searching: boolean;
  loaded: boolean;
}

export const initialState: State = {
  error: null,
  dbSaveStatus: '',
  searching: false,
  groupBy: GroupByNone,
  filterBy: {
    includeAllMatters: false,
    filterKey: EMattersFilter.Current,
  },
  searchText: '',
  advancedSearch: undefined,
  loaded: false,
};

export const reducer = (
  state = initialState,
  action: actions.MatterSearchApiActions | actions.MatterSearchDbActions
): State => {
  switch (action.type) {
    case actions.SEARCH_MATTERS_START: {
      return {
        ...state,
        ...action.payload,
        searching: true,
      };
    }

    case actions.SEARCH_MATTERS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        searching: false,
      };
    }

    case actions.SEARCH_MATTERS_CLOUD_START:
    case actions.SEARCH_MATTERS_CLOUD_FAILURE: {
      return {
        ...state,
      };
    }

    case actions.SEARCH_MATTERS_CLOUD_SUCCESS: {
      const isAdvancedSearch = action.payload.searchMode === AdvancedSearchMode.AdvancedSearch;
      const updatedState = isAdvancedSearch
        ? { ...state }
        : {
            ...state,
            filterBy: { ...state.filterBy, idList: action.payload.data as string[] },
          };
      return {
        ...updatedState,
      };
    }

    case actions.LOAD_MATTERS_SEARCH_START: {
      return {
        ...state,
      };
    }

    case actions.LOAD_MATTERS_SEARCH_SUCCESS: {
      return {
        ...state,
        ...(action.payload ? action.payload : {}),
        loaded: true,
      };
    }

    case actions.SEARCH_SAVE_DB_START: {
      return {
        ...state,
        ...action.payload,
        dbSaveStatus: 'start',
      };
    }

    case actions.SEARCH_SAVE_DB_SUCCESS:
      return {
        ...state,
        dbSaveStatus: action.payload,
        loaded: true,
      };

    case actions.SEARCH_SAVE_DB_FAILURE: {
      return {
        ...state,
        dbSaveStatus: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const extractMatterSearch = (searchState: State): IMatterSearchState => pick(searchState, ['groupBy', 'filterBy', 'searchText', 'advancedSearch']);

export const selectMatterSearch = (state: State) => extractMatterSearch(state);
export const selectSearching = (state: State) => state.searching;
export const selectLoaded = (state: State) => state.loaded;
