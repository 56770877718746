import { ComponentRef, ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Observable } from 'rxjs';

import {
  ErrorHandlerCompData,
  ErrorHandlerCompResponse,
  ErrorHandlerHook,
  ErrorHandlerLogger,
  SiriusError,
} from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { ERROR_HANDLER_CONFIG, ERROR_HANDLER_LOGGER } from '@app/features/error-handler/error-handler.config';
import {
  DEFAULT_ERROR_HANDLER_COMP_DATA,
  DEFAULT_OVERLAY_CONFIG,
  ERROR_INJECTOR_TOKEN,
} from '@app/features/error-handler/constants/error-handler.constants';
import { ErrorHandlerComponent } from '@app/features/error-handler/components';
import { BrandService, PlatformService } from '@app/core/services';
import { AppApiService } from '@app/core/api';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  private overlay: Overlay;
  private logger: ErrorHandlerLogger;

  constructor(private injector: Injector) {
    this.overlay = this.injector.get(Overlay);
    this.logger = this.injector.get(ERROR_HANDLER_LOGGER);
  }

  public handleError(error: any): void {
    const { errorHandlerHooks } = this.injector.get(ERROR_HANDLER_CONFIG);
    // trigger all errorHandlerHooks
    // you could add custom hooks in ERROR_HANDLER_CONFIG
    this.runHooks(errorHandlerHooks, error);

    if (error instanceof Error) {
      this.handleJavascriptError(error);
    } else if (error instanceof HttpErrorResponse) {
      this.logger.error(error); // log the error
    } else if (error instanceof SiriusError) {
      this.showErrorHandlerComponent(error);
    } else {
      this.handleJavascriptError(error);
    }
  }

  private runHooks(errorHandlerHooks: Array<ErrorHandlerHook> = [], error): void {
    errorHandlerHooks.forEach((hook) => hook(error));
  }

  private handleJavascriptError(error: any) {
    this.logger.error(error); // log the error

    const browserSvc = this.injector.get(PlatformService);
    if (browserSvc?.window) {
      // special close current modal navigation if any server error 500 and above.
      // this is only apply to leap apps in popup modal.
      const pathname = (browserSvc.window as Window)?.location.pathname || '';
      if (pathname.includes('popup') && pathname.includes('leap-app-') && error?.httpError?.status >= 500) {
        const toastrSvc = this.injector.get(ToastrService);
        const appApiSvc = this.injector.get(AppApiService);
        const ngZone = this.injector.get(NgZone);
        ngZone.run(() => {
          toastrSvc.show(
            `We are unable to process your request at this time, please try again later.`,
            'Error',
            { disableTimeOut: false, closeButton: true },
            'error'
          );
          appApiSvc?.clearCurrentModal();
        });
        return;
      }
    }

    // if it is not live, we show a toarstr to indicate there is an error
    // if it is live, we would use sentry to log the issue (this is being taken care by runHooks)
    if (!BrandService.isLive()) {
      const toastrSvc = this.injector.get(ToastrService);
      const ngZone = this.injector.get(NgZone);
      ngZone.run(() => {
        toastrSvc.show(
          `Something went wrong.(This message is only for testing purpose and it won't be shown in Live)`,
          'Error',
          { disableTimeOut: true, closeButton: true },
          'error'
        );
      });
    }
  }

  private showErrorHandlerComponent(error: any) {
    const sanitised = this.sanitiseError(error);
    const { overlayConfig } = this.injector.get(ERROR_HANDLER_CONFIG);

    const overlayRef = this.createOverlayReference(overlayConfig);
    const ngZone = this.injector.get(NgZone);
    ngZone.run(() => {
      firstValueFrom(this.attachPortal(overlayRef, sanitised))
        .then((res: ErrorHandlerCompResponse) => {
          if (!!error && !!error.data && !!error.data.onCloseFun) {
            error.data.onCloseFun(res);
          }
          overlayRef.dispose();
        });
    });
  }

  private sanitiseError(error: Error | HttpErrorResponse | SiriusError): ErrorHandlerCompData {
    let sanitisedError: ErrorHandlerCompData = DEFAULT_ERROR_HANDLER_COMP_DATA;
    if (error instanceof Error) {
      this.logger.error(error);
    } else if (error instanceof HttpErrorResponse) {
      this.logger.error(error);
    } else if (error instanceof SiriusError) {
      sanitisedError = {
        ...sanitisedError,
        ...error.data,
      };
      if (error.data.type === 'error') {
        this.logger.error(error.data);
      } else {
        this.logger.warn(error.data);
      }
    } else {
      sanitisedError = {
        ...sanitisedError,
        message: JSON.stringify(error),
      };
      this.logger.error(error);
    }
    return sanitisedError;
  }

  private createOverlayReference(overlayConfig: OverlayConfig): OverlayRef {
    const overlaySettings: OverlayConfig = { ...DEFAULT_OVERLAY_CONFIG, ...overlayConfig };
    return this.overlay.create(overlaySettings);
  }

  private attachPortal(overlayRef: OverlayRef, error: ErrorHandlerCompData): Observable<ErrorHandlerCompResponse> {
    const ErrorHandlerPortal: ComponentPortal<ErrorHandlerComponent> = new ComponentPortal(
      ErrorHandlerComponent,
      null,
      this.createInjector(error)
    );
    const compRef: ComponentRef<ErrorHandlerComponent> = overlayRef.attach(ErrorHandlerPortal);
    return compRef.instance.dismiss$;
  }

  private createInjector(error: ErrorHandlerCompData) {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: ERROR_INJECTOR_TOKEN, useValue: error }
      ]
    });
  }
}
