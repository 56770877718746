import * as appActions from '../actions/app.action';
import * as tableMappingActions from '../actions/tablemapping.action';
import * as sidebarActions from '../actions/sidebar.action';
import * as matterListActions from '@app/features/+matter-list/store/actions/matter-list';
import * as pubnubActions from '../actions/pubnub.action';

import { TableMapping, FirmDetails } from '../../../shared/models';
import { Preference } from '@app/shared/models/user-preferences.model';
import { IGlobalUIPreference } from '../../models';
import { IMatterCore, IMatterListEntry } from '@app/features/+matter-list/models';
import * as recurringMatterActions from '@app/features/+recurring-matter-details/store/actions';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';

export interface CoreState {
  matterEntry: IMatterListEntry;
  matterCore: IMatterCore;
  tableMappings: TableMapping[];
  userPreferences: Preference[];
  userPreferencesDefaults: any;
  uiPreference: IGlobalUIPreference;
  firmDetails: FirmDetails;
  userDetails: UserInfo;
  appDisplayLoading: boolean;
  appLoadingMessage: string;
  recurringMatterName: string;
  walkmeReady: boolean;
}

export const INIT_STATE = {
  tableMappings: [],
  recurringMatterName: null,
  userPreferences: undefined,
  userPreferencesDefaults: undefined,
  uiPreference: {
    asideExpanded: true,
    headerHidden: false,
    officeOnline: false,
    previewAsideExpanded: true,
    resizeExpanded: false,
  },
  matterEntry: undefined,
  matterCore: undefined,
  firmDetails: undefined,
  userDetails: undefined,
  appDisplayLoading: false,
  appLoadingMessage: undefined,
  walkmeReady: false,
};

export const reducer = (
  state: CoreState = INIT_STATE,
  {
    type,
    payload,
  }:
    | appActions.All
    | sidebarActions.SidebarActions
    | tableMappingActions.TableMappingActions
    | matterListActions.MatterListApiActions
    | pubnubActions.PubnubActions
    | recurringMatterActions.RecurringMatterDetailsActions
): CoreState => {
  switch (type) {
    case appActions.GET_MATTER_CORE_SUCCESS: {
      return {
        ...state,
        matterCore: payload && payload.matterCore,
      };
    }

    case appActions.SET_CURRENT_MATTER: {
      return {
        ...state,
        matterEntry: payload && payload.matter,
      };
    }

    case matterListActions.LIST_MATTERS_SUCCESS: {
      const currentMatterId = !!state.matterEntry ? state.matterEntry.matterId : null;
      const currentMatter = payload?.data?.find((d) => d.matterId === currentMatterId);
      return !!currentMatter ? { ...state, matterEntry: currentMatter } : state;
    }

    case recurringMatterActions.SELECT_RECURRING_MATTER_SUCCESS: {
      return {
        ...state,
        recurringMatterName: payload.name,
      };
    }

    case recurringMatterActions.CLEAR_RECURRING_MATTER_DETAILS: {
      return {
        ...state,
        recurringMatterName: null,
      };
    }

    case tableMappingActions.ADD_TABLE_MAPPING_SUCCESS: {
      return {
        ...state,
        tableMappings: payload as TableMapping[],
      };
    }

    case appActions.LOAD_USER_PREFERENCE_SUCCESS: {
      return {
        ...state,
        userPreferences: payload.userPreferences,
        userPreferencesDefaults: payload.userPreferencesDefaults,
      };
    }

    case appActions.UPDATE_USER_PREFERENCE_SUCCESS: {
      return {
        ...state,
        userPreferences: payload,
      };
    }

    case appActions.LOAD_UI_PREFERENCE_DB_SUCCESS: {
      return {
        ...state,
        uiPreference: payload as IGlobalUIPreference,
      };
    }

    case appActions.SAVE_PREVIEW_UI_PREFERENCE:
    case appActions.SAVE_UI_PREFERENCE: {
      return {
        ...state,
        uiPreference: {
          ...state.uiPreference,
          ...(payload as Partial<IGlobalUIPreference>),
        },
      };
    }

    case appActions.SAVE_MATTER: {
      return {
        ...state,
        matterEntry: payload as IMatterListEntry,
      };
    }

    case appActions.ADD_FIRM_DETAILS_SUCCESS: {
      const firmDetails = payload as FirmDetails;
      return {
        ...state,
        firmDetails: { ...firmDetails, emailIntegrationLinked: !!state?.firmDetails?.emailIntegrationLinked },
      };
    }

    case appActions.UPDATE_FIRM_CONSENT: {
      const isGranted = payload as boolean;
      return {
        ...state,
        firmDetails: { ...state?.firmDetails, emailIntegrationLinked: isGranted },
      };
    }

    case appActions.UPDATE_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: payload as UserInfo,
      };
    }

    case appActions.APP_LOADING: {
      return {
        ...state,
        appDisplayLoading: payload,
        appLoadingMessage: !!payload ? state.appLoadingMessage : undefined,
      };
    }

    case appActions.SET_APP_LOADING_MESSAGE: {
      return {
        ...state,
        appLoadingMessage: payload,
      };
    }

    case appActions.CLEAR_APP_LOADING_MESSAGE: {
      return {
        ...state,
        appLoadingMessage: undefined,
      };
    }

    case appActions.CLEAR_MATTER: {
      return {
        ...state,
        matterEntry: null,
        matterCore: null,
      };
    }

    case tableMappingActions.ADD_TABLE_MAPPING_FAILURE:
    case tableMappingActions.ADD_TABLE_MAPPING:
    case appActions.LOAD_UI_PREFERENCE_DB_START:
    case appActions.LOAD_UI_PREFERENCE_DB_FAILURE:
    case appActions.LOAD_USER_PREFERENCE_FAILURE:
    default:
      return state;

    case appActions.SET_WALKME_READY:
      return {
        ...state,
        walkmeReady: payload.status,
      };
  }
};
