import { Action } from '@ngrx/store';
import { INewMatterModalSetupData } from '@app/features/+matter-list/models';

export const NEW_MATTER_MODAL_ON_OPEN = '[New Matter Modal] on open event';
export const GO_TO_MATTER_DETAILS = '[New Matter Modal] go to matter details page';

// card form actions
export const SET_CARD_FORM_VALUE = '[New Matter Modal Card Form] set cardFormValue value on state';

export class NewMatterModalOnOpen implements Action {
  readonly type = NEW_MATTER_MODAL_ON_OPEN;

  constructor(public payload: INewMatterModalSetupData) {}
}

export class SetCardFormValue implements Action {
  readonly type = SET_CARD_FORM_VALUE;

  constructor(public payload: any) {}
}

export class GotoMatterDetails implements Action {
  readonly type = GO_TO_MATTER_DETAILS;

  constructor(public payload: { fileNumber: string }) {}
}

export type NewMatterModalActions = NewMatterModalOnOpen | SetCardFormValue | GotoMatterDetails;
