import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, delay, bufferWhen, switchMap, withLatestFrom, tap } from 'rxjs/operators';

import { AutomationActionType, DocType, FolderValidation, IDoc, IDocsFolder, IOfficeAction } from '@app/shared/models';

import { CorrespondenceListMetaStorageService, CorrespondenceService, FolderService } from '../../services';
import { CorrespondenceListMetaInfo, ISelectCorrespondencePayload } from '../../models';
import * as actions from '../actions';
import { selectCurrentMatterId, selectUserPreferences } from '@app/core/store';
import { Preference } from '@app/shared/models/user-preferences.model';
import { OfficePreferences } from '@app/core/constants/preferences.constant';
import { selectNotificationListSelectedNotification } from '@app/features/+notification/store/selectors';
import * as notificationActions from '@app/features/+notification/store/actions';
import { PlatformService } from '@app/core/services';
import { ENotificationEntityType, INotification } from '@app/features/+notification/models/notification.model';
import { selectMatterId, selectRoot, selectSelectedFolder, selectSelectedFolderId } from '../selectors';
import { CorrespondenceFetchMode } from '../../constants';
import { UserPreferenceTypes } from '@app/core/models';
import { getDocType, isFolder, isPendingPrecedent } from '@app/shared/functions';
import { findFolder } from '../../functions';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { AppApiService } from '@app/core/api';
import { everyMatchCondition, getObjValue } from '@server/modules/shared/functions/common-util.functions';
import { DeleteFolderStart } from '../actions';
import { DialogService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FolderEffects {

  loadCorrespondenceListMeta$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.LoadCorrespondenceListDbStart>(actions.LOAD_CORRESPONDENCE_LIST_DB_START),
    filter(() => this.platformService.isBrowser),
    switchMap((action) => this._metaStorageSvc.getMeta(action.payload).pipe(
        mergeMap((data) => [new actions.LoadCorrespondenceListDbSuccess(data)]),
        catchError((error) => of(new actions.LoadCorrespondenceListDbFailure(error)))
      ))
  ));


  loadCorrespondenceListDbSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.LoadCorrespondenceListDbSuccess>(actions.LOAD_CORRESPONDENCE_LIST_DB_SUCCESS),
    withLatestFrom(this._store.pipe(select(selectCurrentMatterId)), (action, matterId) => !!action.payload && !!action.payload.matterId ? action.payload.matterId : matterId),
    concatMap((matterId: string) => [new actions.ListCorrespondenceListStart({ matterId })])
  ));


  listCorrespondenceList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.ListCorrespondenceListStart>(actions.LIST_CORRESPONDENCE_LIST_START),
    withLatestFrom(this._store.pipe(select(selectSelectedFolderId)), (action, selectedFolderId) => ({
      action,
      selectedFolderId,
    })),
    bufferWhen(() => this.actions$.pipe(
      ofType<actions.ListCorrespondenceListStart>(actions.LIST_CORRESPONDENCE_LIST_START),
      delay(500)
    )),
    map((buf) => {
      if(buf.length === 1) {
        return buf[0];
      }
      const lastAction = Object.assign({}, buf[buf.length - 1]);
      const filtered = buf.filter(action => action.selectedFolderId === lastAction.selectedFolderId);

      // if there is a force documents and force folders actions on the same folder as the last action
      // or there is a force all action on the same folder as the last action
      // we want to do a force all
      // otherwise use the last action
      const getCorrespondenceFetchMode = (fetchModes: string[], last: string) => {
        if(everyMatchCondition([CorrespondenceFetchMode.ForceDocuments, CorrespondenceFetchMode.ForceFolders], (mode) =>
            fetchModes.some((fetchMode) => fetchMode === mode)
          ) || fetchModes.some((fetchMode) => !fetchMode || fetchMode === CorrespondenceFetchMode.ForceAll)
        ) {
          return CorrespondenceFetchMode.ForceAll;
        }
        return last;
      };

      return {
        ...lastAction,
        action: {
          ...lastAction.action,
          payload: {
            ...lastAction.action.payload,
            fetchMode: getCorrespondenceFetchMode(filtered.map(({action}) => action.payload.fetchMode), lastAction.action.payload.fetchMode)
          }
        }
      };
    }),
    concatMap((stateData: { action: actions.ListCorrespondenceListStart; selectedFolderId: string }) => {
      const { matterId } = stateData.action.payload;
      const fetchMode = stateData.action.payload.fetchMode || CorrespondenceFetchMode.ForceAll;

      return this._correspondenceSvc.getCorrespondenceList(matterId, fetchMode).pipe(
        mergeMap((root: IDocsFolder) => {
          const partialMeta: Partial<CorrespondenceListMetaInfo> = {
            root,
            matterId,
            selectedFolder: findFolder(stateData.selectedFolderId, root),
          };
          return [
            new actions.ListCorrespondenceListSuccess(partialMeta),
            new actions.ShowNotificationCorrespondence(null),
            new actions.CorrespondenceListSaveDbStart(partialMeta),
            new actions.ToggleCorrespondenceLoading(false)
          ];
        }),
        catchError((error) => {
          this._store.dispatch(new actions.ListCorrespondenceListFailure(error));
          this._store.dispatch(new actions.ToggleCorrespondenceLoading(false));

          // re-throw error and let error-handler.service to handle this type of error
          return throwError(() =>
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to load matter\'s correspondence list.',
            })
          );
        })
      );
    })
  ));


  showNotificationDocument$ = createEffect(() => this.actions$.pipe(
    ofType<actions.ShowNotificationCorrespondence>(actions.SHOW_NOTIFICATION_CORRESPONDENCE),
    withLatestFrom(
      this._store.pipe(select(selectRoot)),
      this._store.pipe(select(selectNotificationListSelectedNotification)),
      (action, root, selectedNotification) => ({ root, selectedNotification })
    ),
    filter((data) => !!data && !!data.selectedNotification && this.isDocNotification(data.selectedNotification)),
    map((data) => {
      const { root, selectedNotification } = data;
      const correspondenceList = root ? root.fullDocuments : null;
      let doc: IDoc;
      if (!!selectedNotification.entity && !!correspondenceList && correspondenceList.length > 0) {
        doc = correspondenceList.find((d) => d.id === selectedNotification.entity.id);
      }
      return { selectedNotification, doc };
    }),
    switchMap((data) => {
      const { selectedNotification, doc } = data;
      if (!!doc) {
        const payload: ISelectCorrespondencePayload = {
          item: doc,
          action: {
            type: AutomationActionType.Edit,
            mode: 'offline',
            context: 'click',
          },
        };
        return [new actions.SetupSelectCorrespondencePayload(payload)];
      } else {
        // if the document does not exist, we would show an error toastr and acknowledge the selectedNotification
        return [
          new notificationActions.OpenEntityFail({ entityType: selectedNotification.entity.type }),
          new notificationActions.AcknowledgeStickyNotification(null),
        ];
      }
    })
  ));


  saveCorrespondenceListMeta$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.CorrespondenceListSaveDbStart>(actions.CORRESPONDENCE_LIST_SAVE_DB_START),
    withLatestFrom(this._store.pipe(select(selectMatterId)), (action, matterId) => ({ action, matterId })),
    filter(() => this.platformService.isBrowser),
    switchMap((stateData: { action: actions.CorrespondenceListSaveDbStart; matterId: string }) => from(new Promise<{ success: boolean; error: string }>((resolve) => {
        this._metaStorageSvc
          .upsertMeta(stateData.matterId, stateData.action.payload)
          .then(() => resolve({ success: true, error: null }))
          .catch((error) => resolve({ success: false, error: error.message || 'unexpected issue' }));
      })).pipe(map(result => {
        if (result.success) {
          return new actions.CorrespondenceListSaveDbSuccess('success');
        }

        return new actions.CorrespondenceListSaveDbFailure(result.error);
      })))
  ));


  moveToFolder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.MoveToFolderStart>(actions.MOVE_TO_FOLDER_START),
    withLatestFrom(
      this._store.pipe(select(selectMatterId)),
      this._store.pipe(select(selectSelectedFolder)),
      (action, matterId, currentFolder) => ({ action, matterId, currentFolder })
    ),
    mergeMap((stateData: { action: actions.MoveToFolderStart; matterId: string; currentFolder: IDocsFolder }) => {
      const { action, matterId, currentFolder } = stateData;
      const { correspondence, toFolder } = action.payload;
      const isMovingFolder = isFolder(correspondence);
      const request = isMovingFolder
        ? this._folderSvc.moveFolder(matterId, correspondence as IDocsFolder, toFolder)
        : !!currentFolder.id
        ? this._folderSvc.moveDocuments(matterId, [correspondence as IDoc], currentFolder, toFolder)
        : this._folderSvc.addDocuments(matterId, [correspondence as IDoc], toFolder);
      return request.pipe(
        map(() => new actions.MoveToFolderSuccess(null)),
        catchError(() => {
          const moveItemType = isMovingFolder ? 'folder' : 'document';
          return of(new actions.MoveToFolderFailure(`Unable to move ${moveItemType}.`));
        })
      );
    })
  ));


  renameFolder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.RenameFolderStart>(actions.RENAME_FOLDER_START),
    withLatestFrom(this._store.pipe(select(selectMatterId)), (action, matterId) => ({ action, matterId })),
    mergeMap((stateData: { action: actions.RenameFolderStart; matterId: string }) => {
      const { action, matterId } = stateData;
      const { correspondence, name } = action.payload;
      return this._folderSvc.rename(matterId, correspondence as IDocsFolder, name).pipe(
        map(() => new actions.RenameFolderSuccess(null)),
        catchError((error) => {
          this._store.dispatch(new actions.RenameFolderFailure(error));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: toErrorMessage(error),
            })
          );
        })
      );
    })
  ));

  confirmDeleteFolder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.ConfirmDeleteFolder>(actions.CONFIRM_DELETE_FOLDER),
    tap((action) => {
      const numNonDeletedDocuments = action.payload.fullDocuments.filter(({deleted}) => !deleted).length;
      if (numNonDeletedDocuments === 0) {
        this._store.dispatch(new DeleteFolderStart(action.payload));
      } else {
        this._dialogSvc.confirm({
          title: this._translateSvc.instant(`Correspondence.List.Folder.Delete.Modal.Title`),
          message: this._translateSvc.instant(`Correspondence.List.Folder.Delete.Modal.Message`, {numDocs: numNonDeletedDocuments}),
          actionText: this._translateSvc.instant(`Correspondence.List.Folder.Delete.Modal.Action`),
          showCancel: true,
          onClose: (confirmed) => {
            if (confirmed) {
              this._store.dispatch(new DeleteFolderStart(action.payload));
            }
          },
        });
      }
    })
  ), { dispatch: false });


  deleteFolder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteFolderStart>(actions.DELETE_FOLDER_START),
    withLatestFrom(this._store.pipe(select(selectMatterId)), (action, matterId) => ({ action, matterId })),
    mergeMap((stateData: { action: actions.DeleteFolderStart; matterId: string }) =>
      this._folderSvc.delete(stateData.matterId, stateData.action.payload).pipe(
        map(() => new actions.DeleteFolderSuccess(null)),
        catchError((error) => of(new actions.DeleteFolderFailure(error)))
      )
    )
  ));


  createFolder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteFolderStart>(actions.CREATE_FOLDER_START),
    withLatestFrom(this._store.pipe(select(selectMatterId)), (action, matterId) => ({ action, matterId })),
    mergeMap((stateData: { action: actions.DeleteFolderStart; matterId: string }) =>
      this._folderSvc.create(stateData.matterId, stateData.action.payload).pipe(
        map(() => new actions.CreateFolderSuccess(null)),
        catchError((error) => {
          this._store.dispatch(new actions.CreateFolderFailure(error));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: toErrorMessage(error),
            })
          );
        })
      )
    )
  ));


  setupSelectCorrespondencePayload$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.SetupSelectCorrespondencePayload>(actions.SETUP_SELECT_CORRESPONDENCE_PAYLOAD),
    withLatestFrom(this._store.pipe(select(selectUserPreferences)), (action, preferences) => ({
      payload: action.payload,
      preferences,
    })),
    switchMap((data: { payload: ISelectCorrespondencePayload; preferences: Preference[] }) => {
      const { payload, preferences } = data;
      const preference = preferences.find((u) => u.Key === UserPreferenceTypes.OpenOffice);
      if (
        !!preference &&
        !!payload.docType &&
        (payload.docType === DocType.Word || payload.docType === DocType.Excel) &&
        payload.action.context === 'click'
      ) {
        // handleWordAndExcelPreference
        switch (preference.Value) {
          case OfficePreferences.Preview:
            const item = payload.item as IDoc;
            if (isPendingPrecedent(item)) {
              // re-throw error and let error-handler.service to handle this type of error
              return throwError(
                new SiriusError({
                  type: 'warning',
                  title: 'Warning',
                  message: 'Unable to preview a pending precedent.',
                })
              );
            }
            this._appApiSvc.navigate({
              path: [{ outlets: { popup: ['preview'] } }],
              extras: { queryParams: { docGuid: item.id } },
            });
            return [new notificationActions.AcknowledgeStickyNotification(null)];

          case OfficePreferences.OfficeOnline:
            const action: IOfficeAction = { ...payload.action, mode: 'online' };
            const onlinePayload: ISelectCorrespondencePayload = { ...payload, action };
            return [
              new actions.SelectCorrespondence(onlinePayload),
              new notificationActions.AcknowledgeStickyNotification(null),
            ];

          case OfficePreferences.Office:
          default:
            return [
              new actions.SelectCorrespondence(payload),
              new notificationActions.AcknowledgeStickyNotification(null),
            ];
        }
      } else {
        return [new actions.SelectCorrespondence(payload), new notificationActions.AcknowledgeStickyNotification(null)];
      }
    })
  ));


  selectCorrespondence$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SelectCorrespondence>(actions.SELECT_CORRESPONDENCE),
    filter((action) => !!action.payload && !!action.payload.item),
    switchMap((action: actions.SelectCorrespondence) => {
      const docType = getObjValue(action.payload, 'docType');
      if (!!docType) {
        if (docType === DocType.Deleted) {
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'This file is deleted.',
            })
          );
        }

        if (docType === DocType.Undefined) {
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'This file type is not supported.',
            })
          );
        }
      }

      if (isFolder(action.payload.item)) {
        return [
          new actions.CorrespondenceListSaveDbStart({
            selectedFolder: action.payload.item as IDocsFolder,
            selectedFolderId: (action.payload.item as IDocsFolder).id,
          }),
        ];
      } else {
        const document = action.payload.item as IDoc;
        const dType = docType || getDocType(document);

        if (dType === DocType.Word || dType === DocType.Excel) {
          const isOnline =
            !!action.payload && !!action.payload.action ? action.payload.action.mode === 'online' : false;
          return isOnline && !document.desktopOnly
            ? [new actions.EditDocumentOnlineStart(action.payload.action)]
            : [new actions.EditDocumentStart(action.payload.action)];
        }

        if (dType === DocType.Email) {
          return [new actions.OpenEmailInOutlook(document)];
        }

        return [new actions.PreviewDocument(document)];
      }
    })
  ));

  constructor(
    private actions$: Actions,
    private platformService: PlatformService,
    private _folderSvc: FolderService,
    private _appApiSvc: AppApiService,
    private _correspondenceSvc: CorrespondenceService,
    private _metaStorageSvc: CorrespondenceListMetaStorageService,
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateService,
    private _store: Store<any>
  ) { }

  private isDocNotification(notification: INotification): boolean {
    const entityType = notification.entity.type;
    return (
      entityType === ENotificationEntityType.Document ||
      entityType === ENotificationEntityType.Comment ||
      entityType === ENotificationEntityType.Email
    );
  }
}

const toErrorMessage = (error: any): string => {
  switch (error) {
    case FolderValidation.FolderBadInfo:
      return 'Matter id, folder name and parent must not be undefined.';
    case FolderValidation.FolderDuplicated:
      return 'Another folder of the same name exists.';
    default:
      return 'There has been an error.';
  }
};
