import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { layerRightGap, xAnimationLayerGap, xAnimationRouterLayer } from '@app/shared/animations/animations';
import { select, Store } from '@ngrx/store';
import {
  selectRouterLeapApp0LayerGaps,
  selectRouterLeapApp1LayerGaps,
  selectRouterOverlayLayerGaps,
  selectRouterPopupGlobalLayerGaps,
  selectRouterPopupLayerGaps,
  selectRouterSelectorDetailLayerGaps,
  selectRouterSelectorLayerGaps,
} from '@app/core/store/selectors';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { AppApiService } from '@app/core/api';
import { AnalyticsService, LogService, ScHotKeyService } from '@app/core/services';
import { ActivatedRoute } from '@angular/router';
import { KeyPressManagerId } from '@app/shared/models';
import {
  selectBrowserTitlePopupPageName,
  selectBrowserTitlePrimaryPageName,
} from '@app/core/store/selectors/browser-title-state.selector';
import { TranslateService } from '@ngx-translate/core';
import * as browserTitleActions from '@app/core/store/actions';
import { LayerOutletName } from '@app/core/models';

@Component({
  selector: 'sc-proxy-route',
  templateUrl: './proxy-route.component.html',
  animations: [xAnimationRouterLayer, xAnimationLayerGap],
})
export class ProxyRouteComponent implements OnInit, OnDestroy {
  keyPressSub: Subscription;

  public pageNameSub: Subscription;
  public currentLayerNumber = 0;
  public previousLayerNumber = 0;

  private layerGaps$: Observable<number>;
  private layerGabsSub = Subscription.EMPTY;
  private readonly outletName: string;
  private unsub = new Subject<void>();
  private adjustOnlyGlobalIsBased = 0;

  @HostBinding('id') hostId = 'modal-container';

  constructor(
    private _appApiSvc: AppApiService,
    private _hotKeysSvc: ScHotKeyService,
    private _log: LogService,
    private _activatedRoute: ActivatedRoute,
    private _store: Store<any>,
    private _translateSvc: TranslateService,
    private _analyticsSvc: AnalyticsService
  ) {
    this._log.init('proxy-route-component');
    this.outletName = this._activatedRoute.snapshot.outlet;
    const children = this._activatedRoute.parent?.children?.map((c) => c.outlet) || [];

    if (children?.length > 1 && children.includes(LayerOutletName.Global)) {
      const globalIndex = children.findIndex((g) => g === LayerOutletName.Global);
      const comingIndex = children.findIndex((c) => c === this.outletName);

      if (comingIndex > globalIndex) {
        this.adjustOnlyGlobalIsBased = comingIndex;
      }
    }

    switch (this.outletName) {
      case LayerOutletName.LeapAPP0:
        this.layerGaps$ = this._store.pipe(select(selectRouterLeapApp0LayerGaps));
        break;

      case LayerOutletName.LeapAPP1:
        this.layerGaps$ = this._store.pipe(select(selectRouterLeapApp1LayerGaps));
        break;

      case LayerOutletName.PopupGlobal:
        this.layerGaps$ = this._store.pipe(select(selectRouterPopupGlobalLayerGaps));
        break;

      case LayerOutletName.Popup:
        this.layerGaps$ = this._store.pipe(select(selectRouterPopupLayerGaps));
        break;

      case LayerOutletName.Selector:
        this.layerGaps$ = this._store.pipe(select(selectRouterSelectorLayerGaps));
        break;

      case LayerOutletName.Overlay:
        this.layerGaps$ = this._store.pipe(select(selectRouterOverlayLayerGaps));
        break;

      case LayerOutletName.SelectorDetail:
        this.layerGaps$ = this._store.pipe(select(selectRouterSelectorDetailLayerGaps));
        break;
    }

    if (!!this.layerGaps$) {
      this.layerGabsSub = this.layerGaps$
        .pipe(
          distinctUntilChanged(),
          filter((layerGaps) => layerGaps >= 0),
          takeUntil(this.unsub)
        )
        .subscribe((layerGaps) => {
          this.previousLayerNumber = this.currentLayerNumber;
          this.currentLayerNumber = layerGaps;
        });
    }
  }

  @HostBinding('class')
  classes = 'x-overlay';

  @HostBinding('style.zIndex')
  get overLayZIndex(): number {
    return 1040 - this.currentLayerNumber + this.adjustOnlyGlobalIsBased;
  }

  get layerGapAnimation(): any {
    return {
      value: this.currentLayerNumber,
      params: {
        offsetFrom: `${this.previousLayerNumber * layerRightGap}`,
        offsetTo: `${this.currentLayerNumber * layerRightGap}`,
      },
    };
  }

  @HostBinding('@xAnimationRouterLayer')
  get routerLayerAnimation(): boolean {
    return true;
  }

  ngOnInit() {
    this._hotKeysSvc.setupEscKey(this._activatedRoute.outlet as KeyPressManagerId);
    this.setupSubscriptions();
  }

  ngOnDestroy() {
    this.layerGabsSub.unsubscribe();
    this._hotKeysSvc.disposeEscKey(this._activatedRoute.outlet as KeyPressManagerId);
    this.unsub.next();
    this.unsub.complete();
  }

  onBackdropClicked() {
    if (this.outletName === LayerOutletName.Popup) {
      this.sendAnalyticsEvent('click');
    }
    this._appApiSvc.clearCurrentModal();
  }

  public setPopupPageName(): void {
    if (this.outletName === LayerOutletName.Popup) {
      this._store.dispatch(new browserTitleActions.PopupPageInit());
    }
  }

  public clearPopupPageName(): void {
    if (this.outletName === LayerOutletName.Popup) {
      this._store.dispatch(new browserTitleActions.ClearPopupPageName());
    }
  }

  private sendAnalyticsEvent(event: string): void {
    this.pageNameSub = combineLatest([
      this._store.pipe(select(selectBrowserTitlePrimaryPageName)),
      this._store.pipe(select(selectBrowserTitlePopupPageName)),
    ])
      .pipe(
        filter((data) => !!data[1]),
        take(1),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((data) => {
        const popupPageName = this._translateSvc.instant(data[1]);
        const primaryPageName = this._translateSvc.instant(data[0]);
        this._analyticsSvc.sendAnalyticsEvent(primaryPageName, `Cancel`, `${popupPageName} cancelled with ${event}`);
      });
  }

  private setupSubscriptions() {
    this.keyPressSub = this._hotKeysSvc
      .getKeyPressSubject(this._activatedRoute.outlet as KeyPressManagerId)
      .pipe(
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe(() => {
        if (this.outletName === LayerOutletName.Popup) {
          this.sendAnalyticsEvent('key press');
        }
        if (this.isPopup) {
          this._appApiSvc.clearCurrentModal();
        }
      });
  }

  private get isPopup(): boolean {
    return (
      this.outletName === LayerOutletName.Popup ||
      this.outletName === LayerOutletName.Selector ||
      this.outletName === LayerOutletName.Overlay ||
      this.outletName === LayerOutletName.SelectorDetail
    );
  }
}
