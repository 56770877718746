<ng-template *ngIf="visible$ | async" [ngxPermissionsExcept]="permissionsExcept">
  <li
    class="nav-item"
    [tooltip]="'Record Matter Activity'"
    container="body"
    [placement]="'bottom'"
    [container]="'body'"
  >
    <div class="d-flex align-items-center nav-link">
      <sc-icon
        class="x-icon-lg icon-white pr-1"
        iconName="entry-time-grid-20"
      ></sc-icon>
      <sc-switch
        [switchStatus]="enabled$ | async"
        (switchStatusChange)="setBillableState($event)"
      ></sc-switch>
    </div>
  </li>
</ng-template>