import { of as observableOf, throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LogService, PlatformService } from '@app/core/services';
import { DesktopModeWarningComponent } from '@app/shared/components/desktop-mode-warning/desktop-mode-warning.component';
import { DownloadAutomationComponent } from '@app/shared/components/download-automation/download-automation.component';
import { PopupWarningComponent } from '@app/shared/components/popup-warning/popup-warning.component';
import { BrowserService } from '../../services/browser/browser.service';
import { DesktopModeNotSupportedCallback } from '@app/shared/models';
import { isNumber } from '../../../../../server/modules/shared/functions/common-util.functions';

declare const window: any;

@Injectable()
export class UiUtilsService {
  nativeWindow: any;

  constructor(
    private _modalSvc: BsModalService,
    private ps: PlatformService,
    private _browserSvc: BrowserService,
    private _log: LogService
  ) {
    this._log.init('ui-utils-service');
    this.nativeWindow = this.getNativeWindow();
  }

  public getNativeWindow() {
    return this.ps.isBrowser ? window : {};
  }

  public openPopup(url: string, name: string, options: string = null): Observable<string | boolean> {
    if (options === null) {
      const { width, height, left, top } = this.getSizeAndPosition();
      options = `width=${width}, height=${height}, top=${top}, left=${left}`;
    }
    const newWin = this.nativeWindow.open(url, name, options);
    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
      this.handlePopUpBlocked();
      observableThrowError('Popup was blocked');
    }
    return observableOf(true);
  }

  public openPopup2(url: string, name: string, options: string = null): Window | null {
    if (options === null) {
      const { width, height, left, top } = this.getSizeAndPosition();
      options = `width=${width}, height=${height}, top=${top}, left=${left}`;
    }
    const newWin = this.nativeWindow.open(url, name, options);
    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
      this.handlePopUpBlocked();
      observableThrowError('Popup was blocked');
      return null;
    }
    return newWin;
  }

  public handlePopUpBlocked() {
    console.log('handlePopUpBlocked');
    return this._modalSvc.show(PopupWarningComponent, { backdrop: 'static', class: 'modal-lg' });
  }

  public handleDesktopModeNotSupported(initialState?: DesktopModeNotSupportedCallback) {
    return this._modalSvc.show(DesktopModeWarningComponent, { backdrop: 'static', initialState });
  }

  public handleUriNotSupported() {
    return this._modalSvc.show(DownloadAutomationComponent, { backdrop: 'static' });
  }

  public getSizeAndPosition(width = 1000, height = 800) {
    // http://stackoverflow.com/a/16861050

    const dualScreenLeft = isNumber(this.nativeWindow.screenLeft)
      ? this.nativeWindow.screenLeft
      : (this.nativeWindow.screen as any).left;

    const dualScreenTop = isNumber(this.nativeWindow.screenTop)
      ? this.nativeWindow.screenTop
      : (this.nativeWindow.screen as any).top;

    const windowWidth = this.nativeWindow.innerWidth
      ? this.nativeWindow.innerWidth
      : this.nativeWindow.document.documentElement.clientWidth
      ? this.nativeWindow.document.documentElement.clientWidth
      : this.nativeWindow.screen.width;

    const windowHeight = this.nativeWindow.innerHeight
      ? this.nativeWindow.innerHeight
      : this.nativeWindow.document.documentElement.clientHeight
      ? this.nativeWindow.document.documentElement.clientHeight
      : this.nativeWindow.screen.height;

    const left = windowWidth * 0.5 - width * 0.5 + dualScreenLeft;
    const top = windowHeight * 0.5 - height * 0.5 + dualScreenTop;
    return { width, height, left, top };
  }
}
