import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  delayWhen,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { environment } from '@env/environment';
import { IMatterCard, IntegrationProvider } from '@app/shared/models';
import * as actions from '../actions/current-matter';
import * as appActions from '@app/core/store';
import { selectCurrentMatter, selectCurrentMatterId, selectCurrentStaff, selectFirmDetails } from '@app/core/store';
import { InfoTrackService, MatterDetailsService } from '../../services';
import { State } from '../reducers';
import { DialogService } from '@app/shared/services';

import { IMatterListEntry } from '@app/features/+matter-list/models';
import {
  selectCurrentMatterLoading,
  selectDetailInfo,
  selectMatterCardRelationships,
} from '../selectors/current-matter.selectors';
import { PlatformService } from '@app/core/services';
import { AppApiService } from '@app/core/api';
import { IMatterDetailsApiResponse } from '../../models/matter-details-api.model';
import { MatterListStorageService } from '@app/features/+matter-list/services';
import { EInfoTrackType } from '@app/core/models';
import { MatterTablesService } from '@app/features/+matter-table-types/services';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import * as currentMatterActions from '@app/features/+matter-details/store/actions/current-matter';
import { Regions } from '@app/shared/models/config.model';

@Injectable()
export class CurrentMatterEffects {

  getCurrentMatter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_CURRENT_MATTER),
    filter(() => !!this._platformService.isBrowser),
    withLatestFrom(this._matterListStorageSvc.getAllStream()),
    switchMap(([action, matters]: [actions.GetCurrentMatter, IMatterListEntry[]]) => this._matterDetailsSvc.get(action.payload.matterId, matters).pipe(
        filter((matter) => !!matter),
        mergeMap((matter) => [new actions.CurrentMatterSuccess(matter)]),
        catchError((error) => {
          this._store.dispatch(new actions.CurrentMatterFailure(error));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to get matter entry.',
            })
          );
        })
      ))
  ));


  currentMatterSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.CurrentMatterSuccess>(actions.CURRENT_MATTER_SUCCESS),
    filter(() => !!this._platformService.isBrowser),
    switchMap((action) =>
      // Not saving to db at the moment
       [
        new appActions.SaveMatter(action.payload),
        new appActions.GetMatterCore({ matterId: action.payload.matterId }),
      ]
    )
  ));


  getMatterDetailsStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_MATTER_DETAILS_START),
    switchMap((action: actions.GetMatterDetailsStart) => this._matterDetailsSvc.getMatterDetails(action.payload).pipe(
        mergeMap((resp: IMatterDetailsApiResponse) => [new actions.GetMatterDetailsSuccess(resp)]),
        catchError((error) => {
          this._store.dispatch(new actions.GetMatterDetailsFailure(error));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to load matter details.',
            })
          );
        })
      ))
  ));


  getMatterCardsStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_MATTER_CARDS_START),
    switchMap((action: actions.GetMatterCardsStart) => this._matterDetailsSvc.getMatterCards(action.payload).pipe(
        map((resp: IMatterCard[]) => new actions.GetMatterCardsSuccess(resp)),
        catchError((error) => of(new actions.GetMatterCardsFailure(error)))
      ))
  ));


  reorderMatterDetailEntriesStart$: any = createEffect(() => this.actions$.pipe(
    ofType<actions.ReorderMatterDetailEntriesStart>(actions.REORDER_MATTER_DETAIL_ENTRIES_START),
    withLatestFrom(this._store.pipe(select(selectCurrentMatterId))),
    mergeMap((data: any) => {
      const [action, matterId] = data;
      return this._matterTablesSvc.reOrderMatterDetails(matterId, action.payload).pipe(
        map(() => new actions.ReorderMatterDetailEntriesSuccess(null)),
        catchError(() => [new actions.ReorderMatterDetailEntriesFail(null)])
      );
    })
  ));


  reorderMatterDetailEntriesFail$: any = createEffect(() => this.actions$.pipe(
    ofType(actions.REORDER_MATTER_DETAIL_ENTRIES_Fail),
    tap(() => {
      this._dialogSvc.error({ message: this._translateSvc.instant('Matter.Card.Reorder.Error.Message') });
    })
  ), { dispatch: false });


  addActingPersonStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.ADD_ACTING_PERSON_START),
    mergeMap((action: actions.AddActingPersonStart) => {
      const { matterId, matterCard, person } = action.payload;
      return this._matterDetailsSvc.addActingPerson(matterId, matterCard, person).pipe(
        map(() => new actions.PersonActingSuccess(null)),
        catchError(() => {
          this._store.dispatch(new actions.PersonActingFailure(null));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to add acting person.',
            })
          );
        })
      );
    })
  ));


  removeActingPersonStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.REMOVE_ACTING_PERSON_START),
    mergeMap((action: actions.RemoveActingPersonStart) => {
      const { matterId, matterCard, person } = action.payload;
      return this._matterDetailsSvc.removeActingPerson(matterId, matterCard, person).pipe(
        map(() => new actions.PersonActingSuccess(null)),
        catchError(() => {
          this._store.dispatch(new actions.PersonActingFailure(null));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to remove acting person.',
            })
          );
        })
      );
    })
  ));


  updateMatterDetailEntries$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_MATTER_DETAIL_ENTRIES),
    mergeMap((action: actions.UpdateMatterDetailEntries) => {
      const { matterId, entries } = action.payload;
      return this._matterTablesSvc.updateMatterTables(matterId, entries).pipe(
        mergeMap(() => [new currentMatterActions.SetDetailEntries({ detailEntries: entries })]),
        catchError(() =>
          // re-throw error and let error-handler.service to handle this type of error
           throwError(
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to update matter detail entries.',
            })
          )
        )
      );
    })
  ));


  reorderMatterDetailsWithRelationships$: Observable<void> = createEffect(() => this.actions$.pipe(
    ofType<actions.ReorderRelationsMatterDetailsStart>(actions.REORDER_RELATIONS_MATTER_DETAIL_ENTRIES_START),
    withLatestFrom(
      this._store.pipe(select(selectCurrentMatterId)),
      this._store.pipe(select(selectMatterCardRelationships))
    ),
    mergeMap((data) => {
      const [action, matterId, relationships] = data;
      const entries = this._matterDetailsSvc.reOrderDetailsWithRelationships(action.payload, relationships);
      return this._matterTablesSvc.updateMatterTables(matterId, entries);
    })
  ), { dispatch: false });


  goToFormsPrecedents$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GoToFormsPrecedents>(actions.GO_TO_FORMS_PRECEDENTS),
    delayWhen(() =>
      // we need to delay the navigation until we have both matterState and matterTypeId from matterDetails
      this._store.pipe(
        select(selectDetailInfo),
        filter((details) => !!details && !!details.matterState && !!details.matterTypeId),
        take(1)
      )
    ),
    withLatestFrom(this._store.pipe(select(selectDetailInfo)), (action, detailInfo) => detailInfo),
    tap((detailInfo) => {
      const { matterTypeId, matterState } = detailInfo;
      this._appApiSvc.formsAndPrecedents({ matterTypeId, state: matterState });
    })
  ), { dispatch: false });


  goToMatterOptions$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GoToMatterOptionsModalPage>(actions.GO_TO_MATTER_OPTIONS_MODAL_PAGE),
    delayWhen(() =>
      this._store.pipe(
        select(selectCurrentMatterLoading),
        filter((currentMatterLoading) => !currentMatterLoading)
      )
    ),
    tap(() => this._appApiSvc.openMatterOptions())
  ), { dispatch: false });


  infotrackNewWin$ = createEffect(() => this.actions$.pipe(
    ofType<actions.InfotrackNewWin>(actions.INFOTRACK_NEW_WIN),
    filter(() => !!this._platformService.isBrowser),
    delayWhen(() =>
      combineLatest([
        this._store.pipe(select(selectFirmDetails)),
        this._store.pipe(select(selectCurrentMatter)),
        this._store.pipe(select(selectCurrentStaff)),
      ]).pipe(
        filter((data) => {
          const [firmDetails, currentMatter, currentStaff] = data;
          return !!firmDetails && !!currentMatter && !!currentStaff;
        }),
        take(1)
      )
    ),
    withLatestFrom(
      this._store.pipe(select(selectCurrentMatter)),
      this._store.pipe(select(selectFirmDetails)),
      this._store.pipe(select(selectCurrentStaff)),
      (action, currentMatter, firmDetails, currentStaff) => ({
          matterId: currentMatter.matterId,
          userDetails: {
            firmId: firmDetails.id,
            staffId: currentStaff.__id,
            firstName: currentStaff.firstName,
            lastName: currentStaff.lastName,
            fullName: currentStaff.fullName,
            userId: currentStaff.userId,
            email: currentStaff.email,
          },
          provider: firmDetails ? firmDetails.defaultSearchProvider : IntegrationProvider.InfoTrack,
          region: !!firmDetails && !!firmDetails.region ? firmDetails.region : Regions.AU,
          env: environment.config.brand.env,
          type: action.payload.type,
          url: action.payload.url,
          matter: {
            fileNumber: currentMatter.fileNumber,
            deleteCode: currentMatter.deleteCode,
            accessible: currentMatter.accessible,
          },
        })
    ),
    exhaustMap((data) => {
      const isMatterValid = data?.matter?.deleteCode !== 1 && data.matter.accessible;
      if (!isMatterValid) {
        this._infoTrackSvc.handleIntegrationError({
          message: `Unfortunately, inactive matter is not supported by ${data.provider}`,
        });
        return [];
      }

      return this._infoTrackSvc.getInfotrackBase(data).pipe(
        mergeMap((res) => {
          const { mappingId, token, provider, success, settingsUrl } = res;
          if (!success) {
            if (!!settingsUrl) {
              this._infoTrackSvc.handleIntegrationErrorWithSettingUrl({
                settingsUrl,
                provider,
              });
              return;
            }

            this._infoTrackSvc.handleIntegrationError({ provider });
            return;
          }

          if (data.type === EInfoTrackType.Url) {
            const infotrackUrl = this._infoTrackSvc.getInfoTrackUrl({ baseUrl: data.url, mappingId, token });
            window.open(infotrackUrl, 'InfoTrack');

            return [];
          }

          const {
            matter: { fileNumber },
            region,
          } = data;

          let url = '';

          switch (data.type) {
            case EInfoTrackType.Search:
              url = res.url;
              break;
            case EInfoTrackType.Fees:
              url = this._infoTrackSvc.getInfoTrackFeesUrl({ fileNumber, mappingId, token, provider });
              break;
            case EInfoTrackType.Support:
              url = this._infoTrackSvc.getInfoTrackSupportUrl({ mappingId, token, region, provider });
              break;
          }

          if (url) {
            window.open(url, 'InfoTrack');
          }

          return [];
        }),
        catchError((err) => {
          const { settingsUrl, provider } = err;
          if (!!settingsUrl) {
            this._infoTrackSvc.handleIntegrationErrorWithSettingUrl({
              settingsUrl,
              provider,
            });
          } else {
            this._infoTrackSvc.handleIntegrationError({
              provider,
            });
          }
          return [];
        })
      );
    })
  ), { dispatch: false });


  updateLayoutRendererCriticalDates$ = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateLayoutRendererCriticalDatesStart>(actions.UPDATE_LAYOUT_RENDERER_CRITICAL_DATES_START),
    mergeMap((action) => {
      const { matterId, criticalDates } = action.payload;
      return this._matterDetailsSvc.updateLayoutRendererCriticalDates(matterId, criticalDates).pipe(
        map((res) => new actions.UpdateLayoutRendererCriticalDatesSuccess(null)),
        catchError((err) => [new actions.UpdateLayoutRendererCriticalDatesFailure(err)])
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private _appApiSvc: AppApiService,
    private _store: Store<State>,
    private _platformService: PlatformService,
    private _matterListStorageSvc: MatterListStorageService,
    private _matterDetailsSvc: MatterDetailsService,
    private _matterTablesSvc: MatterTablesService,
    private _dialogSvc: DialogService,
    private _infoTrackSvc: InfoTrackService,
    private _translateSvc: TranslateService
  ) {}
}
